import React, { useEffect } from 'react';
import { Autocomplete, Box, CircularProgress, FormHelperText, Grid, Popper, TextField } from '@mui/material';

export default function SurveyBasicDetailsForm({ data, isSuccess }) {

    return (

        isSuccess ?
            (
                < Grid container spacing={3} >
                    <Grid item xs={12}>
                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="surveyname"
                            name="surveyname"
                            label="Survey Name"
                            value={data?.survey_name}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="statename"
                            name="statename"
                            label="State Name"
                            value={data?.state_name}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="districtname"
                            name="districtname"
                            label="District Name"
                            value={data?.district_name}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="constituencyname"
                            name="constituencyname"
                            label="Constituency Name"
                            value={data?.constituency_name}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid >
            ) :
            (
                <Box textAlign={"center"}>
                    <CircularProgress />
                </Box>
            )


    );
}
