import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { Link as LinkRouterDom } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import routeUrls from "../../constants/routeUrls";
import TopBar from "../../components/TopBar";
import {
  GoogleMap,
  InfoWindow,
  LoadScript,
  MarkerF,
  useJsApiLoader,
} from "@react-google-maps/api";
import { useCallback, useRef, useState } from "react";
import { useGetMapDetailsQuery } from "../../api/reportsApi";

export default function MapReports() {
  const [selectedLocation, setSelectedLocation] = useState(null);

  const containerStyle = {
    width: "100%",
    height: "400px",
  };

  const center = {
    lat: 18.912066,
    lng: 75.612989,
  };

  const breadcrumbs = [
    <Link
      key="dashboard"
      component={LinkRouterDom}
      underline="none"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(103, 58, 183)"
      to={routeUrls.DASHBOARD}
    >
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    </Link>,
    <Typography
      key="reports"
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      Reports
    </Typography>,
    <Typography
      key="map-reports"
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      Map Reports
    </Typography>,
  ];

  const {
    data: mapResponseList,
    isSuccess: isMapResponseListSuccess,
    isFetching: isMapResponseListFetching,
    refetch: surveyResponseListRefetch,
  } = useGetMapDetailsQuery({ refetchOnMountOrArgChange: true });

  const handleMarkerClick = (location) => {
    setSelectedLocation(location);
  };

  const { isLoaded, loadError } = useJsApiLoader({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY, // Replace with your actual API key
  });

  return (
    <>
      <TopBar title={"Map Reports"} breadcrumbs={breadcrumbs} />

      <Box mt={2}>
        <Card elevation={0} sx={{ borderRadius: 3 }}>
          <CardHeader />
          <CardContent>
            {!isLoaded &&
              isMapResponseListFetching &&
              !isMapResponseListSuccess && <CircularProgress />}
            {isLoaded &&
              !isMapResponseListFetching &&
              isMapResponseListSuccess && (
                <GoogleMap
                  mapContainerStyle={containerStyle}
                  center={center}
                  zoom={4}
                >
                  {mapResponseList?.data?.map((location) => (
                    <MarkerF
                      key={location.details_id}
                      position={{
                        lat: parseFloat(location.latitude),
                        lng: parseFloat(location.longitude),
                      }}
                      onClick={() => handleMarkerClick(location)}
                    ></MarkerF>
                  ))}
                </GoogleMap>
              )}
          </CardContent>
          {selectedLocation && (
            <CardContent>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Survey Name</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Latitude</TableCell>
                      <TableCell>Longitude</TableCell>
                      <TableCell>TimeLog</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    <TableRow>
                      <TableCell>{selectedLocation.survey_name}</TableCell>
                      <TableCell>{selectedLocation.username}</TableCell>
                      <TableCell>{selectedLocation.latitude}</TableCell>
                      <TableCell>{selectedLocation.longitude}</TableCell>
                      <TableCell>{selectedLocation.time_log}</TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </CardContent>
          )}
        </Card>
      </Box>
    </>
  );
}
