import { STATUS, localStorageKeys } from '../../../constants/constants';
import { createSlice } from '@reduxjs/toolkit';
import { loginUser } from './authActions';

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isAuthenticated: false || localStorage.getItem(localStorageKeys.isAuthenticated),
    status: STATUS.IDLE,
    error: ''
  },
  reducers: {
    logoutUser: state => {
      state.isAuthenticated = false;
      state.error = '';
      state.status = STATUS.IDLE;
      localStorage.clear();
    },
    resetAuthStatus: state => {
      state.isAuthenticated = false;
      state.status = STATUS.IDLE;
      localStorage.clear();
    }
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state, action) => {
        state.status = STATUS.LOADING
      })
      .addCase(loginUser.fulfilled, (state, action) => {
        state.status = STATUS.SUCCESS;
        state.isAuthenticated = true;
        localStorage.setItem(localStorageKeys.isAuthenticated, state.isAuthenticated);
      })
      .addCase(loginUser.rejected, (state, action) => {
        state.status = STATUS.FALIED
      })
  }
})

// Action creators are generated for each case reducer function
export const { logoutUser, resetAuthStatus } = authSlice.actions;

export default authSlice.reducer;