import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Popper, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import useEditModal from "./useEditModal";
import { useEffect } from "react";
import { useGetDistrictQuery } from "../../../api/districtApi";

export default function EditConstituencyModal({ id, openModal, handleCloseModal }) {

    const { formik, refetch, isLoading, isSuccess, handleSubmit } = useEditModal({ id, handleCloseModal });

    const { data: districtData, isSuccess: isDistrictSuccess, refetch: districtRefetch } = useGetDistrictQuery({ page: 1, pageSize: 10000 });

    useEffect(() => {
        refetch();
        districtRefetch();
    }, [])

    return (
        <>
            <Dialog fullWidth sx={{
                '.MuiDialog-paper': {
                    borderRadius: 4
                }
            }} open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id="customized-dialog-title">
                    Edit Constituency
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>

                    {
                        isSuccess ?
                            (

                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                                fullWidth
                                                id="constituencyName"
                                                name="constituencyName"
                                                label="Constituency"
                                                required
                                                value={formik.values.constituencyName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.constituencyName && Boolean(formik.errors.constituencyName)}
                                                helperText={formik.touched.constituencyName && formik.errors.constituencyName}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                                disablePortal={false}
                                                fullWidth
                                                id="districtID"
                                                name="districtID"
                                                loading={!isDistrictSuccess}
                                                onBlur={formik.handleBlur}
                                                options={districtData?.data || []}
                                                getOptionLabel={(district) => district?.district_name || ''}
                                                value={districtData?.data?.find(district => district.id == formik.values.districtID) || null}
                                                onChange={(event, newValue) => formik.setFieldValue('districtID', newValue ? newValue.id : '')}
                                                renderInput={(params) => <TextField required error={formik.touched.districtID && formik.errors.districtID} {...params} label="District" />}
                                                PopperComponent={({ children, ...rest }) => (
                                                    <Popper {...rest} placement="top-start">
                                                        {children}
                                                    </Popper>
                                                )}
                                            />

                                            {formik.touched.districtID && formik.errors.districtID && (
                                                <FormHelperText sx={{ ml: 2 }} error>{formik.errors.districtID}</FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            ) :
                            (
                                <Box textAlign={"center"}>
                                    <CircularProgress />
                                </Box>
                            )
                    }

                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" sx={{
                        backgroundColor: (theme) => theme.palette.cancel.main,
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.cancel.main, // Adjust hover color if needed
                        }, textTransform: 'none'
                    }} onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} disabled={!formik.isValid} variant="contained" sx={{ textTransform: 'none' }} autoFocus onClick={handleSubmit}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}