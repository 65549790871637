import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";


export const countApi = createApi({

    reducerPath: 'countApi',

    baseQuery: baseQuery,

    endpoints: builder => ({
        getCount: builder.query({
            query: () => ({
                url: `/count.php`,
                method: 'GET'
            })
        }),
    })
})

export const { useGetCountQuery } = countApi;