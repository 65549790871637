import { useGetGeneralSummaryReportsQuery, useGetSummaryReportsByAgeQuery, useGetSummaryReportsByCasteQuery, useGetSummaryReportsByVillageQuery, } from "../../../api/reportsApi";

const useConditionalSummaryReportsQuery = ({ id, selectedSurvey }) => {
    // Declare queries for different survey types
    const villageQuery = useGetSummaryReportsByVillageQuery({ id });
    const generalQuery = useGetGeneralSummaryReportsQuery({ id });
    const castQuery = useGetSummaryReportsByCasteQuery({ id });
    const ageQuery = useGetSummaryReportsByAgeQuery({ id });

    // Use the selectedSurvey value to decide which query to return
    let selectedQuery;

    switch (selectedSurvey) {
        case 'survey-by-village':
            selectedQuery = villageQuery;
            break;
        case 'survey-by-age':
            selectedQuery = ageQuery;
            break;
        case 'survey-by-cast':
            selectedQuery = castQuery;
            break;
        default:
            selectedQuery = generalQuery;
            break;
    }

    return selectedQuery;
};

export default function useChart({ surveyId, selectedSurvey }) {

    const { data: formData, isSuccess: isFormSuccess, isFetching: isFormFetching, refetch } = useConditionalSummaryReportsQuery({ id: surveyId, selectedSurvey: selectedSurvey });
    return {
        formData,
        refetch,
        isFormFetching,
        isFormSuccess
    }
}