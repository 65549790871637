import { useState } from "react";
import { useGetStateByIdQuery } from "../../../api/stateApi";

export default function useViewModal({ id }) {

    const { data, isSuccess, isFetching, refetch } = useGetStateByIdQuery({ id: id });
    
    return {
        data,
        isSuccess: (isSuccess && !isFetching),
        refetch
    }
}