import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Popper, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useAddModal from "./useAddModal";
import { LoadingButton } from "@mui/lab";

export default function AddAssignSurveyModal({ userData, isUserSuccess, surveyData, isSurveySuccess, openModal, handleCloseModal, handleUpdateCallback }) {

    const { formik, isLoading, handleSubmit } = useAddModal({ handleCloseModal, handleUpdateCallback });

    return (
        <>
            <Dialog fullWidth sx={{
                '.MuiDialog-paper': {
                    borderRadius: 4
                }
            }} open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id="customized-dialog-title">
                    Assign Survey
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <Autocomplete
                                    sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                    disablePortal={false}
                                    fullWidth
                                    id="userID"
                                    name="userID"
                                    loading={!isUserSuccess}
                                    onBlur={formik.handleBlur}
                                    options={userData|| []}
                                    getOptionLabel={(user) => user?.username || ''} // Display state name
                                    value={userData?.find(user => user.id === formik.values.userID) || null} // Find state object based on stateID
                                    onChange={(event, newValue) => formik.setFieldValue('userID', newValue ? newValue.id : '')} // Pass state ID
                                    renderInput={(params) => <TextField required error={formik.touched.userID && formik.errors.userID} {...params} label="User" />}
                                />

                                {formik.touched.userID && formik.errors.userID && (
                                    <FormHelperText sx={{ ml: 2 }} error>{formik.errors.userID}</FormHelperText>
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                    disablePortal={false}
                                    fullWidth
                                    id="surveyID"
                                    name="surveyID"
                                    loading={!isSurveySuccess}
                                    onBlur={formik.handleBlur}
                                    options={surveyData?.data || []}
                                    getOptionLabel={(survey) => survey?.survey_name || ''} // Display state name
                                    value={surveyData?.data?.find(survey => survey.id === formik.values.surveyID) || null} // Find state object based on stateID
                                    onChange={(event, newValue) => formik.setFieldValue('surveyID', newValue ? newValue.id : '')} // Pass state ID
                                    renderInput={(params) => <TextField required error={formik.touched.surveyID && formik.errors.surveyID} {...params} label="Survey" />}
                                    PopperComponent={({ children, ...rest }) => (
                                        <Popper {...rest} placement="top-start">
                                            {children}
                                        </Popper>
                                    )}
                                />

                                {formik.touched.surveyID && formik.errors.surveyID && (
                                    <FormHelperText sx={{ ml: 2 }} error>{formik.errors.surveyID}</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" sx={{
                        backgroundColor: (theme) => theme.palette.cancel.main,
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.cancel.main, // Adjust hover color if needed
                        }, textTransform: 'none'
                    }} onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} disabled={!formik.isValid || !formik.values.surveyID || !formik.values.userID} variant="contained" sx={{ textTransform: 'none' }} autoFocus onClick={handleSubmit}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}