import { useLazyGetUserStatisticsQuery } from "../../../api/reportsApi";
import { useGetSurveyQuery } from "../../../api/surveyApi";

export default function useChart({ surveyId, selectedSurvey }) {
  const [
    triggerUserStatistics,
    {
      data: formData,
      isSuccess: isFormSuccess,
      isFetching: isFormFetching,
      refetch,
    },
  ] = useLazyGetUserStatisticsQuery({
    id: surveyId,
  });
  return {
    triggerUserStatistics,
    formData,
    refetch,
    isFormFetching,
    isFormSuccess,
  };
}
