import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { errorToast, successToast } from '../../../utils/toastService';
import { useEditDistrictByIdMutation, useGetDistrictByIdQuery } from '../../../api/districtApi';


export default function useEditModal({ id, handleCloseModal }) {

    const [editDistrict, { isLoading }] = useEditDistrictByIdMutation();
    const { data, isSuccess, refetch, isFetching } = useGetDistrictByIdQuery({ id: id });

    const validationSchema = yup.object({
        districtName: yup
            .string()
            .required('District Name is required'),
        stateID: yup
            .number()
            .required('State is required'),
    });

    const formik = useFormik({
        initialValues: {
            districtName: data?.name || '',
            stateID: data?.state_id || 0,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {

            const formData = {
                "state_id": values.stateID,
                "district": values.districtName
            }

            editDistrict({ id: id, data: JSON.stringify(formData) })
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('District Updated Successfully');
                        formik.resetForm();
                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Updated District');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Updated District');
                })
                .finally(() => {
                    handleCloseModal();
                });

        },
    });

    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik,
        isSuccess : (isSuccess && !isFetching),
        refetch
    }
}