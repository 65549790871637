import { useState } from "react";
import { useReactToPrint } from "react-to-print";

export default function useStatistics() {
  const [pdfDownloading, setPdfDownloading] = useState(false);

  const generatePDF = useReactToPrint({
    documentTitle: "User Statistics",
    onBeforePrint: () => setPdfDownloading(true),
    onAfterPrint: () => setPdfDownloading(false),
    removeAfterPrint: true,
  });

  return {
    generatePDF,
    pdfDownloading,
  };
}
