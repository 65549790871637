import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import routeUrls from "../../constants/routeUrls";
import { STATUS } from "../../constants/constants";
import { Bounce, ToastContainer, toast } from "react-toastify";
import { resetAuthStatus } from "../../store/feature/auth/authSlice";
import { useEffect } from "react";
import { errorToast } from "../../utils/toastService";

export default function LoginLayout() {

    const dispatch = useDispatch();
    const { isAuthenticated, status } = useSelector(state => state.auth);
    const location = useLocation();


    useEffect(() => {
        if (status === STATUS.FALIED) {
            errorToast('Invalid username or password')
            dispatch(resetAuthStatus());
        }
    }, [status, dispatch]); // Add status and dispatch as dependencies

    if (isAuthenticated) {
        console.log("depth");
        return <Navigate to={routeUrls.DASHBOARD} state={{ from: location }} />
    }

    return (
        <>
            <Box>
                <ToastContainer />
                <Outlet />
            </Box>
        </>
    )
}