import { useFormik } from "formik";
import * as yup from "yup";
import { errorToast, successToast } from "../../../utils/toastService";
import { useAddCasteMutation } from "../../../api/casteApi";

export default function useAddModal({ handleCloseModal }) {
  const [addCaste, { isLoading }] = useAddCasteMutation();

  const validationSchema = yup.object({
    casteName: yup.string().required("Caste Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      casteName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.casteName);

      addCaste(formData)
        .unwrap()
        .then((response) => {
          if (response?.success) {
            successToast("Caste Added Successfully");
            formik.resetForm();
          } else if (response?.error) {
            errorToast(response.error.data.message);
          } else {
            errorToast("Unable To Add Caste");
          }
        })
        .catch((error) => {
          errorToast("Unable To Add Caste");
        })
        .finally(() => {
          handleCloseModal();
        });
    },
  });

  return {
    handleSubmit: formik.handleSubmit,
    isLoading: isLoading,
    formik,
  };
}
