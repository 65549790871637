import React, { useState } from 'react';
import { Box, Button, Divider, FormControl, Grid, Radio, Checkbox, IconButton, MenuItem, Select, TextField } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';

export default function AddQuestionsForm({ formik }) {

    const { values, handleChange, handleBlur } = formik;

    const handleAddQuestion = () => {
        formik.setValues({
            ...values,
            questions: [...values.questions, { type: 'answer', label: '', options: [] }],
        });
    };

    const handleRemoveQuestion = (index) => {
        const updatedQuestions = [...values.questions];
        updatedQuestions.splice(index, 1);
        formik.setValues({ ...values, questions: updatedQuestions });
    };

    const handleQuestionChange = (index, field, value) => {
        let updatedQuestions = [...values.questions];
        updatedQuestions[index][field] = value;
        if(field=='type'){
            if (value === 'radio' || value === 'checkbox') {
                // Initialize with one option if the question type is radio or checkbox
                updatedQuestions[index].options = ['Option 1'];
            } else {
                // Reset options if the question type is changed to text input
                updatedQuestions[index].options = [];
            }
        }
        formik.setValues({ ...values, questions: updatedQuestions });
    };

    const handleOptionChange = (questionIndex, optionIndex, value) => {
        const updatedQuestions = [...values.questions];
        updatedQuestions[questionIndex].options[optionIndex] = value;
        formik.setValues({ ...values, questions: updatedQuestions });
    };

    const handleAddOption = (questionIndex) => {
        const updatedQuestions = [...values.questions];
        const optionCount = updatedQuestions[questionIndex].options.length + 1;
        updatedQuestions[questionIndex].options.push(`Option ${optionCount}`);
        formik.setValues({ ...values, questions: updatedQuestions });
    };

    const handleRemoveOption = (questionIndex, optionIndex) => {
        const updatedQuestions = [...values.questions];
        updatedQuestions[questionIndex].options.splice(optionIndex, 1);
        formik.setValues({ ...values, questions: updatedQuestions });
    };

    return (
        <Box sx={{ padding: '20px' }}>
            {values?.questions?.map((question, index) => (
                <Box key={index} sx={{ border: '1px solid #ccc', borderRadius: '5px', padding: '15px', marginBottom: '20px' }}>
                    <Grid spacing={2} container>
                        <Grid item xs={8}>
                            <TextField
                                label={`Question ${index + 1}`}
                                fullWidth
                                value={question.label}
                                onChange={(e) => handleQuestionChange(index, 'label', e.target.value)}
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControl fullWidth>
                                <Select
                                    value={question.type}
                                    onChange={(e) => handleQuestionChange(index, 'type', e.target.value)}
                                >
                                    <MenuItem value="answer">Text Input</MenuItem>
                                    <MenuItem value="radio">Radio</MenuItem>
                                    <MenuItem value="checkbox">Checkbox</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                    {question.type === 'radio' && (
                        <Box sx={{ marginTop: '10px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddOption(index)}
                                startIcon={<AddIcon />}
                                sx={{ marginBottom: '10px', mb: 3, borderRadius: 5 }}
                            >
                                Add Option
                            </Button>

                            {question.options.map((option, optionIndex) => (
                                <Box key={optionIndex} display="flex" alignItems="center" mb={1}>
                                    <Radio disabled sx={{
                                        '&.Mui-disabled': {
                                            color: 'primary.main',
                                        },
                                    }} />
                                    <TextField
                                        fullWidth
                                        variant='standard'
                                        value={option}
                                        onChange={(e) =>
                                            handleOptionChange(index, optionIndex, e.target.value)
                                        }
                                        onBlur={(e) => {
                                            if (!e.target.value.trim()) {
                                                handleOptionChange(index, optionIndex, `Option ${optionIndex + 1}`);
                                            }
                                        }}
                                    />
                                    {question.options.length > 1 && (
                                        <IconButton onClick={() => handleRemoveOption(index, optionIndex)}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    )}
                    {question.type === 'checkbox' && (
                        <Box sx={{ marginTop: '10px' }}>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={() => handleAddOption(index)}
                                startIcon={<AddIcon />}
                                sx={{ marginBottom: '10px', mb: 3, borderRadius: 5 }}
                            >
                                Add Option
                            </Button>

                            {question.options.map((option, optionIndex) => (
                                <Box key={optionIndex} display="flex" alignItems="center" mb={1}>
                                    <Checkbox disabled sx={{
                                        '&.Mui-disabled': {
                                            color: 'primary.main',
                                            mr: 0.5
                                        },
                                    }} />
                                    <TextField
                                        fullWidth
                                        value={option}
                                        variant='standard'

                                        onChange={(e) =>
                                            handleOptionChange(index, optionIndex, e.target.value)
                                        }
                                        onBlur={(e) => {
                                            if (!e.target.value.trim()) {
                                                handleOptionChange(index, optionIndex, `Option ${optionIndex + 1}`);
                                            }
                                        }}
                                    />
                                    {question.options.length > 1 && (
                                        <IconButton onClick={() => handleRemoveOption(index, optionIndex)}>
                                            <HighlightOffIcon />
                                        </IconButton>
                                    )}
                                </Box>
                            ))}
                        </Box>
                    )}
                    <Divider sx={{ marginTop: '20px' }} />
                    <Box textAlign={"end"}>
                        <IconButton onClick={() => handleRemoveQuestion(index)}>
                            <DeleteOutlineIcon />
                        </IconButton>
                    </Box>
                </Box>
            ))}
            <Box sx={{ textAlign: "center" }}>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddIcon />}
                    onClick={handleAddQuestion}
                    sx={{ marginTop: '20px', borderRadius: 5 }}
                >
                    Add Question
                </Button>
            </Box>
        </Box>
    );
}
