const STATUS = {
    IDLE: 'idle',
    LOADING: 'loading',
    SUCCESS: 'succeeded',
    FALIED: 'failed'
}

const localStorageKeys = {
    isAuthenticated: 'isAuthenticated',
    email: 'email',
    selectedRoute: 'selectedRoute'
}

export { STATUS, localStorageKeys };