import { Box, Button, Link, Typography } from "@mui/material";
import { Link as LinkRouterDom } from "react-router-dom";
import TopBar from "../../components/TopBar";
import HomeIcon from "@mui/icons-material/Home";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../utils/toastService";
import DataTable from "./DataTable";
import ConfirmationDialogBox from "../../components/ConfirmationDialogBox";
import {
  useDeleteVillageByIdMutation,
  useGetVillageQuery,
} from "../../api/villageApi";
import ViewVillageModal from "./ViewVillageModal";
import AddVillageModal from "./AddVillageModal";
import EditVillageModal from "./EditVillageModal";
import routeUrls from "../../constants/routeUrls";

export default function VillageList() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState("");
  const { data, isSuccess, isFetching, refetch } = useGetVillageQuery(
    { page, pageSize, search },
    { refetchOnMountOrArgChange: true }
  );
  const [deleteVillage] = useDeleteVillageByIdMutation();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchValue = (value) => {
    setSearch(value);
  };

  const breadcrumbs = [
    <Link
      component={LinkRouterDom}
      underline="none"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(103, 58, 183)"
      to={routeUrls.DASHBOARD}
    >
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    </Link>,
    <Typography
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      Survey Location
    </Typography>,
    <Typography
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      Village List
    </Typography>,
  ];

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState({
    id: 0,
    open: false,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState({
    id: 0,
    open: false,
  });
  const [openEditModal, setOpenEditModal] = useState({
    id: 0,
    open: false,
  });

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    refetch();
  };

  const handleOpenViewModal = ({ id }) => {
    setOpenViewModal({
      id: id,
      open: true,
    });
  };

  const handleCloseViewModal = () => {
    setOpenViewModal({
      id: 0,
      open: false,
    });
  };

  const handleOpenDeleteModal = ({ id }) => {
    console.log(id);
    setOpenDeleteModal({
      id: id,
      open: true,
    });
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal({
      id: 0,
      open: false,
    });
  };

  const handleDeleteVillage = () => {
    deleteVillage({ id: openDeleteModal?.id })
      .then((response) => {
        if (response?.data?.success) {
          successToast("Deleted Village Successfully");
          refetch();
        } else {
          errorToast("Unable To Delete Village");
        }
      })
      .catch((error) => {
        errorToast("Unable To Delete Village");
      });
    handleCloseDeleteModal();
  };

  const handleOpenEditModal = ({ id }) => {
    setOpenEditModal({
      id: id,
      open: true,
    });
  };

  const handleCloseEditModal = () => {
    setOpenEditModal({
      id: 0,
      open: false,
    });
    refetch();
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <TopBar title={"Village"} breadcrumbs={breadcrumbs} />

      {/* Table */}
      <Box mt={2}>
        <DataTable
          headers={["#", "Constituency Name", "Village Name", "Actions"]}
          action={
            <Button
              variant="contained"
              onClick={handleOpenAddModal}
              sx={{ textTransform: "none", fontWeight: 600 }}
            >
              Add Village
            </Button>
          }
          data={data?.data}
          totalPages={data?.pagination?.totalPages}
          page={page}
          pageSize={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenViewModal={handleOpenViewModal}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      <AddVillageModal
        openModal={openAddModal}
        handleCloseModal={handleCloseAddModal}
      />

      {!!openViewModal?.id && (
        <ViewVillageModal
          id={openViewModal?.id}
          openModal={openViewModal?.open}
          handleCloseModal={handleCloseViewModal}
        />
      )}

      <ConfirmationDialogBox
        open={openDeleteModal.open}
        title={"Are you want to delete the village?"}
        onAccept={handleDeleteVillage}
        onReject={handleCloseDeleteModal}
      />

      {!!openEditModal?.id && (
        <EditVillageModal
          id={openEditModal?.id}
          openModal={openEditModal?.open}
          handleCloseModal={handleCloseEditModal}
        />
      )}
    </>
  );
}
