import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";


export const responseSurveyApi = createApi({

    reducerPath: 'responseSurveyApi',

    baseQuery: baseQuery,

    endpoints: builder => ({
        getResponseSurveyById: builder.query({
            query: ({ id }) => ({
                url: `/getAnswer.php?id=${id}`,
                method: 'GET'
            })
        }),
    })
})

export const { useGetResponseSurveyByIdQuery } = responseSurveyApi;