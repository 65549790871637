import { Box, Typography } from "@mui/material";
import DataTable from "./DataTable";

export default function SurveyByCaste({ formData, getTotalCount }) {
    return (
        <>
            {
                formData?.length ? (
                    <Box px={3}>
                        {
                            formData?.map((data, index) => (
                                <Box key={index}>
                                    {
                                        index !== 0 && <Box className="page-break" sx={{
                                            display: "block",
                                            pageBreakBefore: 'always'
                                        }}></Box>
                                    }
                                    <Box key={index} mt={5}>
                                        <Typography variant="h6" fontWeight={"bold"} textAlign={"center"} mb={5}>Caste: {data?.caste || "N/A"}</Typography>

                                        {
                                            data?.questions?.map((question, index) => (
                                                <Box key={index}>
                                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
                                                        Q{index + 1}: {question.question_text}
                                                    </Typography>
                                                    <DataTable headers={[
                                                        '#',
                                                        'Option Name',
                                                        'Total Count',
                                                        'Percentage'
                                                    ]} data={question?.options} totalCount={getTotalCount(question)} />
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Box>
                            ))}
                    </Box>
                ) : (
                    <Box textAlign="center" p={2}>No Records Found</Box>
                )
            }
        </>
    )
}