import React, { useEffect } from 'react';
import { Autocomplete, Box, CircularProgress, FormHelperText, Grid, Popper, TextField } from '@mui/material';
import { useGetStateQuery } from '../../../../api/stateApi';
import { useGetDistrictQuery } from '../../../../api/districtApi';
import { useGetConstituencyQuery } from '../../../../api/constituencyApi';

export default function SurveyBasicDetailsForm({ formik, isSuccess }) {

    const { data: stateData, isSuccess: isStateSuccess, refetch: stateRefetch } = useGetStateQuery({ page: 1, pageSize: 10000 });
    const { data: districtData, isSuccess: isDistrictSuccess, refetch: districtRefetch } = useGetDistrictQuery({ page: 1, pageSize: 10000 });
    const { data: constituencyData, isSuccess: isConstituencySuccess, refetch: constituencyRefetch } = useGetConstituencyQuery({ page: 1, pageSize: 10000 });

    useEffect(() => {
        stateRefetch();
        districtRefetch();
        constituencyRefetch();
    }, [])

    return (
        isSuccess ?
            (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="surveyname"
                            name="surveyname"
                            label="Survey Name"
                            required
                            value={formik.values.surveyname}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            error={formik.touched.surveyname && Boolean(formik.errors.surveyname)}
                            helperText={formik.touched.surveyname && formik.errors.surveyname}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            disablePortal={false}
                            fullWidth
                            id="stateID"
                            name="stateID"
                            loading={!isStateSuccess}
                            onBlur={formik.handleBlur}
                            options={stateData?.data || []}
                            getOptionLabel={(state) => state?.name || ''}
                            value={stateData?.data?.find(state => state.id === formik.values.stateID) || null}
                            onChange={(event, newValue) => {
                                formik.setFieldValue('stateID', newValue ? newValue.id : '');
                                formik.setFieldValue('districtID', ''); // Reset districtID when state changes
                                formik.setFieldValue('constituencyID', '');
                            }}
                            renderInput={(params) => <TextField required error={formik.touched.stateID && formik.errors.stateID} {...params} label="State" />}
                            PopperComponent={({ children, ...rest }) => (
                                <Popper {...rest} placement="top-start">
                                    {children}
                                </Popper>
                            )}
                        />

                        {formik.touched.stateID && formik.errors.stateID && (
                            <FormHelperText sx={{ ml: 2 }} error>{formik.errors.stateID}</FormHelperText>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <Autocomplete
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            disablePortal={false}
                            fullWidth
                            id="districtID"
                            name="districtID"
                            loading={!isDistrictSuccess}
                            onBlur={formik.handleBlur}
                            disabled={!formik.values.stateID}
                            options={districtData?.data?.filter(district => district.state_id == formik.values.stateID) || []}
                            getOptionLabel={(district) => district?.district_name || ''}
                            value={districtData?.data?.find(district => district.id === formik.values.districtID) || null}
                            onChange={(event, newValue) => {
                                formik.setFieldValue('districtID', newValue ? newValue.id : '')
                                formik.setFieldValue('constituencyID', '');
                            }}
                            renderInput={(params) => <TextField required error={formik.touched.districtID && formik.errors.districtID} {...params} label="District" />}
                            PopperComponent={({ children, ...rest }) => (
                                <Popper {...rest} placement="top-start">
                                    {children}
                                </Popper>
                            )}
                        />

                        {formik.touched.districtID && formik.errors.districtID && (
                            <FormHelperText sx={{ ml: 2 }} error>{formik.errors.districtID}</FormHelperText>
                        )}
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            disablePortal={false}
                            fullWidth
                            id="constituencyID"
                            name="constituencyID"
                            loading={!isConstituencySuccess}
                            onBlur={formik.handleBlur}
                            disabled={!formik.values.districtID}
                            options={constituencyData?.data?.filter(constituency => constituency.district_id === formik.values.districtID) || []}
                            getOptionLabel={(constituency) => constituency?.constituency_name || ''}
                            value={constituencyData?.data?.find(constituency => constituency.id === formik.values.constituencyID) || null}
                            onChange={(event, newValue) => formik.setFieldValue('constituencyID', newValue ? newValue.id : '')}
                            renderInput={(params) => <TextField required error={formik.touched.constituencyID && formik.errors.constituencyID} {...params} label="Constituency" />}
                            PopperComponent={({ children, ...rest }) => (
                                <Popper {...rest} placement="top-start">
                                    {children}
                                </Popper>
                            )}
                        />

                        {formik.touched.constituencyID && formik.errors.constituencyID && (
                            <FormHelperText sx={{ ml: 2 }} error>{formik.errors.constituencyID}</FormHelperText>
                        )}
                    </Grid>
                </Grid>
            ) :
            (
                <Box textAlign={"center"}>
                    <CircularProgress />
                </Box>
            )


    );
}
