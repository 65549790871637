import { useState } from "react";
import { useGetConstituencyByIdQuery } from "../../../api/constituencyApi";

export default function useViewModal({ id }) {

    const { data, isSuccess, isFetching, refetch } = useGetConstituencyByIdQuery({ id: id });

    return {
        data,
        isSuccess : (isSuccess && !isFetching),
        refetch
    }
}