import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { errorToast, successToast } from '../../../utils/toastService';
import { useAddDistrictMutation } from '../../../api/districtApi';


export default function useAddModal({ handleCloseModal }) {

    const [addDistrict, { isLoading }] = useAddDistrictMutation();

    const validationSchema = yup.object({
        districtName: yup
            .string()
            .required('District Name is required'),
        stateID: yup
            .number()
            .required('State is required'),
    });

    const formik = useFormik({
        initialValues: {
            districtName: '',
            stateID: 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const formData = new FormData();
            formData.append("state_id",values.stateID);
            formData.append("district",values.districtName);

            addDistrict(formData)
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('District Added Successfully');
                        formik.resetForm();
                        
                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Add District');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Add District');
                })
                .finally(() => {
                    handleCloseModal();
                });
        },
    });


    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik
    }
}