import { useFormik } from "formik";
import * as yup from "yup";
import { errorToast, successToast } from "../../../utils/toastService";
import {
  useEditCasteByIdMutation,
  useGetCasteByIdQuery,
} from "../../../api/casteApi";

export default function useEditModal({ id, handleCloseModal }) {
  const [editCaste, { isLoading }] = useEditCasteByIdMutation();
  const { data, isSuccess, refetch, isFetching } = useGetCasteByIdQuery({
    id: id,
  });

  const validationSchema = yup.object({
    casteName: yup.string().required("Caste Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      casteName: data?.data?.name || "",
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const formData = {
        name: values.casteName,
      };

      editCaste({ id: id, data: JSON.stringify(formData) })
        .unwrap()
        .then((response) => {
          if (response?.success) {
            successToast("Caste Updated Successfully");
            formik.resetForm();
          } else if (response?.error) {
            errorToast(response.error.data.message);
          } else {
            errorToast("Unable To Updated Caste");
          }
        })
        .catch((error) => {
          errorToast("Unable To Updated Caste");
        })
        .finally(() => {
          handleCloseModal();
        });
    },
  });

  return {
    handleSubmit: formik.handleSubmit,
    isLoading: isLoading,
    formik,
    isSuccess: isSuccess && !isFetching,
    refetch,
  };
}
