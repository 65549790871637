import { Avatar, Box, Chip, Divider, ListItemIcon, Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import ProfileImg from "../../../../assets/images/profile.jpg";
import { IconSettings } from "@tabler/icons-react";
import { useState } from "react";
import { Logout } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../../store/feature/auth/authSlice";


export default function ProfileSection() {

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const dispatch = useDispatch();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleLogout = () => {
        dispatch(logoutUser());
    }

    return (
        <>
            <Tooltip title="Account Settings">
                <Chip
                    onClick={handleClick}
                    sx={{
                        height: '48px',
                        alignItems: 'center',
                        borderRadius: '27px',
                        transition: 'all .2s ease-in-out',
                        backgroundColor: open ? "rgb(33, 150, 243)" : "rgb(227, 242, 253)",
                        borderColor: 'white',
                        color: open ? 'white' : 'rgb(33, 150, 243)',
                        cursor: 'pointer',
                        ':hover': {
                            backgroundColor: "rgb(33, 150, 243) !important",
                            color: 'white !important'
                        }
                    }}
                    icon={<Avatar src={ProfileImg} sx={{
                        margin: '8px 0 8px 8px !important',
                        width: 35, height: 35
                    }} />}
                    label={<IconSettings stroke={1.5} size="1.5rem" display={"flex"} />}
                    variant="outlined"
                />
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        borderRadius: '10px',
                        '& .MuiList-root ':{
                            p: '10px',
                        },
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&::before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <Box sx={{ p: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant="subtitle1" fontWeight="bold" sx={{ color: 'rgb(33, 150, 243)', mr: 1 }}>
                            Welcome,
                        </Typography>
                        <Typography variant="subtitle1" sx={{ color: 'rgb(105, 117, 134)', fontFamily: 'Arial, sans-serif', fontSize: '0.9rem', fontWeight: 'bold' }}>
                            Admin
                        </Typography>
                    </Box>
                </Box>

                <Divider />

                <MenuItem sx={{
                    m: 1, p: 1, borderRadius: 3,
                    ':hover': {
                        backgroundColor: 'secondary.main', // Change background color on hover
                        color: 'primary.main', // Change text color on hover
                        '& .MuiListItemIcon-root': {
                            color: 'primary.main', // Change icon color on hover
                        }
                    },
                }}
                    onClick={handleLogout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>

            </Menu>
        </>
    )
}