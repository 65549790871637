import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";


export const userApi = createApi({

    reducerPath: 'userApi',

    baseQuery: baseQuery,

    endpoints: builder => ({
        getUser: builder.query({
            query: ({ page = 1, pageSize = 10, search = '' }) => ({
                url: `/user.php?q=${search}&pageSize=${pageSize}&page=${page}`,
                method: 'GET'
            })
        }),
        getAssignUser: builder.query({
            query: () => ({
                url: `/userAssign.php`,
                method: 'GET'
            })
        }),
        addUser: builder.mutation({
            query: (data) => ({
                url: '/user.php',
                method: 'POST',
                body: data
            })
        }),
        getUserById: builder.query({
            query: ({ id }) => ({
                url: `/user.php?id=${id}`,
                method: 'GET'
            })
        }),

        editUserById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/user.php?id=${id}`,
                method: 'PUT',
                body: data
            })
        }),

        deleteUserById: builder.mutation({
            query: ({ id }) => ({
                url: `/user.php?id=${id}`,
                method: 'DELETE'
            })
        }),
    })
})

export const { useGetUserQuery, useGetAssignUserQuery, useGetUserByIdQuery, useAddUserMutation, useDeleteUserByIdMutation, useEditUserByIdMutation } = userApi;