const routeUrls = {
  LOGIN: "/",

  // Overview
  DASHBOARD: "/overview/dashboard",
  USERS: "/overview/users",
  STATISTICS: "/overview/statistics",

  // SurveyLocation
  STATELIST: "/location/state-list",
  DISTRICTLIST: "/location/district-list",
  CONSTITUENCYLIST: "/location/constituency-list",
  VILLAGELIST: "/location/village-list",
  CASTELIST: "/location/caste-list",

  // Survey
  SURVEYSLIST: "/surveys/list",
  ASSIGNSURVEY: "/surveys/assign",

  // Reports
  USERSREPORTS: "/reports/users-reports",
  USERSTATISTICS: "/reports/users-statistics",
  FORMREPORTS: "/reports/form-reports",
  SUMMARYREPORTS: "/reports/summary-reports",
  MAPREPORTS: "/reports/map-reports",
};

export default routeUrls;
