import { Box, Typography } from "@mui/material";
import DataTable from "./DataTable";

export default function GeneralSurvey({formData, getTotalCount}) {
    return (
        <>
            {
                formData?.questions?.length ? (
                    <Box px={3}>
                        {
                            formData.questions?.map((question, index) => (

                                <Box key={index}>
                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
                                        Q{index + 1}: {question.question_text}
                                    </Typography>
                                    <DataTable headers={[
                                        '#',
                                        'Option Name',
                                        'Total Count',
                                        'Percentage'
                                    ]} data={question?.options} totalCount={getTotalCount(question)} />
                                </Box>
                            ))}
                    </Box>
                ) : (
                    <Box textAlign="center" p={2}>No Records Found</Box>
                )
            }
        </>
    )
}