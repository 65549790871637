import React, { useState } from 'react';
import { Box, Button, CircularProgress, Divider, Radio, Checkbox, FormControl, Grid, IconButton, MenuItem, Select, TextField } from '@mui/material';


export default function AddQuestionsForm({ data, isSuccess }) {

    const values = data;

    return (

        isSuccess ?
            (
                <Box sx={{ padding: '20px' }}>
                    {values?.questions?.map((question, index) => (
                        <Box key={index} sx={{ border: '1px solid #ccc', borderRadius: '5px', padding: '15px', marginBottom: '20px' }}>
                            <Grid spacing={2} container>
                                <Grid item xs={8}>
                                    <TextField
                                        label={`Question ${index + 1}`}
                                        fullWidth
                                        value={question.question_text}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </Grid>
                                <Grid item xs={4}>
                                    <FormControl fullWidth>
                                        <Select
                                            value={question.question_type.toLowerCase()}
                                            readOnly={true}
                                        >
                                            <MenuItem value="answer">Text Input</MenuItem>
                                            <MenuItem value="radio">Radio</MenuItem>
                                            <MenuItem value="checkbox">Checkbox</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {question.question_type.toLowerCase() === 'radio' && (
                                <Box sx={{ marginTop: '10px' }}>
                                    {question.options.map((option, optionIndex) => (
                                        <Box key={optionIndex} display="flex" alignItems="center" mb={1}>
                                            <Radio disabled sx={{
                                                '&.Mui-disabled': {
                                                    color: 'primary.main',
                                                },
                                            }} />
                                            <TextField
                                                fullWidth
                                                variant='standard'
                                                value={option.option_text}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            )}
                            {question.question_type.toLowerCase() === 'checkbox' && (
                                <Box sx={{ marginTop: '10px' }}>

                                    {question.options.map((option, optionIndex) => (
                                        <Box key={optionIndex} display="flex" alignItems="center" mb={1}>
                                            <Checkbox disabled sx={{
                                                    '&.Mui-disabled': {
                                                        color: 'primary.main',
                                                        mr: 0.5
                                                    },
                                                }} />
                                            <TextField
                                                fullWidth
                                                variant='standard'
                                                value={option.option_text}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </Box>
                                    ))}

                                </Box>
                            )}
                        </Box>
                    ))}
                </Box>
            ) :
            (
                <Box textAlign={"center"}>
                    <CircularProgress />
                </Box>
            )


    );
}
