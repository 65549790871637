import { useFormik } from 'formik';
import * as yup from 'yup';
import { errorToast, successToast } from '../../../utils/toastService';
import { useAddVillageMutation } from '../../../api/villageApi';


export default function useAddModal({ handleCloseModal }) {

    const [addVillage, { isLoading }] = useAddVillageMutation();

    const validationSchema = yup.object({
        villageName: yup
            .string()
            .required('Village Name is required'),
        constituencyID: yup
            .number()
            .required('Constituency is required'),
    });

    const formik = useFormik({
        initialValues: {
            villageName: '',
            constituencyID: 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const formData = new FormData();
            formData.append("constituency_id", values.constituencyID);
            formData.append("village", values.villageName);

            addVillage(formData)
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('Village Added Successfully');
                        formik.resetForm();

                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Add Village');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Add Village');
                })
                .finally(() => {
                    handleCloseModal();
                });
        },
    });


    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik
    }
}