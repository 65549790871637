import { useState } from "react";
import { useGetDistrictByIdQuery } from "../../../api/districtApi";

export default function useViewModal({ id }) {

    const { data, isSuccess, isFetching, refetch } = useGetDistrictByIdQuery({ id: id });

    return {
        data,
        isSuccess : (isSuccess && !isFetching),
        refetch
    }
}