import { Box, Card, CardContent, CardHeader, Divider, Typography } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import { useEffect } from "react";
import useChart from "./useChart";
import BasicDetails from "./BasicDetails";
import DataTable from "./DataTable";
import GeneralSurvey from "./GeneralSurvey";
import SurveyByVillage from "./SurveyByVillage";
import SurveyByCaste from "./SurveyByCaste";
import SurveyByAge from "./SurveyByAge";

export default function Chart({ surveyId, surveyDetail, setSurveyReportsSuccess, title, action, selectedSurvey, contentToPrint }) {

    const { formData, refetch, isFormFetching, isFormSuccess } = useChart({ surveyId, selectedSurvey });

    useEffect(() => {
        refetch();
    }, []);

    useEffect(() => {
        setSurveyReportsSuccess(isFormSuccess && !isFormFetching)
    }, [isFormFetching, isFormSuccess])

    const getTotalCount = (question) => Object.values(question.options).reduce((total, count) => total + count, 0);

    return (
        <Card elevation={0} sx={{ borderRadius: 3 }}>
            <CardHeader
                sx={{
                    '.MuiCardHeader-action': { alignSelf: "center", mr: 1 }
                }}
                title={title}
                action={action}
            />
            <Divider />
            <CardContent ref={contentToPrint} style={{ paddingBottom: '16px'  }}>
                {surveyId ? (
                    <>
                        {isFormSuccess && !isFormFetching ? (
                            <Box>

                                <BasicDetails data={surveyDetail} />
                                <Divider sx={{ my: 2 }} />
                                {
                                    selectedSurvey=='general-survey'?
                                    <GeneralSurvey formData={formData} getTotalCount={getTotalCount}/>
                                    :selectedSurvey=='survey-by-village'?
                                    <SurveyByVillage formData={formData} getTotalCount={getTotalCount}/>
                                    :selectedSurvey=='survey-by-cast'?
                                    <SurveyByCaste formData={formData} getTotalCount={getTotalCount}/>
                                    :<SurveyByAge formData={formData} getTotalCount={getTotalCount}/>
                                }
                                
                            </Box>
                        ) : (
                            <Box textAlign="center" p={2}><CircularProgress /></Box>
                        )}
                    </>
                ) : (
                    <Box textAlign="center" p={2}>Please Select a Survey to View the Chart</Box>
                )}
            </CardContent>
        </Card>
    );
}
