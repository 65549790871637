import { useState } from "react";
import { useGetUserByIdQuery } from "../../../api/userApi"

export default function useViewModal({ id }) {

    const { data, isSuccess, isFetching, refetch } = useGetUserByIdQuery({ id: id });
    
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    return {
        data,
        isSuccess : (isSuccess && !isFetching),
        refetch,
        showPassword, handleClickShowPassword,
    }
}