import { Box, Typography } from "@mui/material";
import DataTable from "./DataTable";

export default function SurveyByAge({ formData, getTotalCount }) {
    return (
        <>
            {formData && Object.keys(formData).length ? (
                <Box px={3}>
                    {
                        Object.entries(formData).map(([ageGroup, data], index) => (
                            <Box key={index}>
                                {
                                    index !== 0 && <Box className="page-break" sx={{
                                        display: "block",
                                        pageBreakBefore: 'always'
                                    }}></Box>
                                }
                                <Box mt={5}>
                                    <Typography variant="h6" fontWeight="bold" textAlign="center" mb={5}>
                                        Age Group: {ageGroup}
                                    </Typography>
                                    <Box>
                                        {
                                            Object.values(data.questions).map((question, index) => (
                                                <Box key={index} mt={2}>
                                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                                                        {`Q${index + 1}: ${question.question_text}`}
                                                    </Typography>
                                                    <Box>
                                                        <DataTable headers={[
                                                            '#',
                                                            'Option Name',
                                                            'Total Count',
                                                            'Percentage'
                                                        ]} data={question?.options} totalCount={getTotalCount(question)} />
                                                    </Box>
                                                </Box>
                                            ))}
                                    </Box>
                                </Box>
                            </Box>
                        ))}
                </Box>
            ) : (
                <Box textAlign="center" p={2}>No Records Found</Box>
            )}
        </>
    )
}