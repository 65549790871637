import React from 'react';
import { Box, Button, Grid, Typography } from '@mui/material';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import { LoadingButton } from '@mui/lab';

export default function SubmitSurveyForm({formik, isLoading, handleSubmit}) {

    return (
        <Box p={3}>
            <Grid container spacing={3} alignItems="center" justifyContent="center" direction="column">
                <Grid item>
                    <DoneAllIcon sx={{ fontSize: 80, color: 'green', mb: 2 }} />
                </Grid>
                <Grid item>
                    <Typography variant="h6" align="center">Confirm and Submit Survey</Typography>
                </Grid>
                <Grid item>
                    <Typography variant="body1" align="center">
                        Please review your survey details and questions before submitting.
                    </Typography>
                </Grid>
                <Grid item>
                    <LoadingButton loading={isLoading} disabled={!formik.isValid}variant="contained" color="primary" onClick={handleSubmit}>Submit</LoadingButton>
                </Grid>
            </Grid>
        </Box>
    );
}
