import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Popper,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { LoadingButton } from "@mui/lab";
import useEditModal from "./useEditModal";
import { useEffect } from "react";

export default function EditCasteModal({ id, openModal, handleCloseModal }) {
  const { formik, refetch, isLoading, isSuccess, handleSubmit } = useEditModal({
    id,
    handleCloseModal,
  });

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <Dialog
        fullWidth
        sx={{
          ".MuiDialog-paper": {
            borderRadius: 4,
          },
        }}
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }}
          id="customized-dialog-title"
        >
          Edit Caste
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleCloseModal}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {isSuccess ? (
            <form onSubmit={handleSubmit}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextField
                    sx={{
                      ".MuiInputBase-root": {
                        backgroundColor: "rgb(248, 250, 252)",
                      },
                    }}
                    fullWidth
                    id="casteName"
                    name="casteName"
                    label="Caste"
                    required
                    value={formik.values.casteName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    error={
                      formik.touched.casteName &&
                      Boolean(formik.errors.casteName)
                    }
                    helperText={
                      formik.touched.casteName && formik.errors.casteName
                    }
                  />
                </Grid>
              </Grid>
            </form>
          ) : (
            <Box textAlign={"center"}>
              <CircularProgress />
            </Box>
          )}
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Button
            variant="contained"
            sx={{
              backgroundColor: (theme) => theme.palette.cancel.main,
              "&:hover": {
                backgroundColor: (theme) => theme.palette.cancel.main, // Adjust hover color if needed
              },
              textTransform: "none",
            }}
            onClick={handleCloseModal}
          >
            Cancel
          </Button>
          <LoadingButton
            loading={isLoading}
            disabled={!formik.isValid}
            variant="contained"
            sx={{ textTransform: "none" }}
            autoFocus
            onClick={handleSubmit}
          >
            Save
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}
