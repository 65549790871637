import { useGetAssignSurveyByIdQuery } from "../../../api/assignSurveyApi";
import { useGetResponseSurveyByIdQuery } from "../../../api/responseSurveyApi";

export default function useViewModal({ id }) {

    const { data, isSuccess, isFetching, refetch } = useGetResponseSurveyByIdQuery({ id: id });

    return {
        data,
        isSuccess : (isSuccess && !isFetching),
        refetch
    }
}