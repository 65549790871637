import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as yup from 'yup';
import { loginUser } from "../../store/feature/auth/authActions";
import { useState } from "react";
import { STATUS } from "../../constants/constants";

const validationSchema = yup.object({
    email: yup
        .string('Enter your email')
        .email('Enter a valid email')
        .required('Email is required'),
    password: yup
        .string('Enter your password')
        .required('Password is required'),
});

export default function useLoginHook() {

    const { status } = useSelector(state => state.auth);
    const [showPassword, setShowPassword] = useState(false);
    const dispatch = useDispatch();
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const { email, password } = values;
            
            const formData = new FormData();
            formData.append('username',email);
            formData.append('password',password);

            dispatch(loginUser(formData));
        },
    });

    return {
        formik,
        showPassword,
        loading: status === STATUS.LOADING,
        handleClickShowPassword,
        handleSubmit: formik.handleSubmit
    }
}