import { createTheme } from "@mui/material";

const theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(103, 58, 183)',
        },
        secondary: {
            main: 'rgb(237, 231, 246)'
        },
        
        cancel: {
            main: '#ff5722', // Custom color for cancel button
        },
    },
});

export default theme;