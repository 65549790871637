import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";

export const casteApi = createApi({
  reducerPath: "casteApi",

  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getCaste: builder.query({
      query: ({ page = 1, pageSize = 10, search = "" }) => ({
        url: `/caste.php?q=${search}&pageSize=${pageSize}&page=${page}`,
        method: "GET",
      }),
    }),
    addCaste: builder.mutation({
      query: (data) => ({
        url: "/caste.php",
        method: "POST",
        body: data,
      }),
    }),
    getCasteById: builder.query({
      query: ({ id }) => ({
        url: `/caste.php?id=${id}`,
        method: "GET",
      }),
    }),

    editCasteById: builder.mutation({
      query: ({ id, data }) => ({
        url: `/caste.php?id=${id}`,
        method: "PUT",
        body: data,
      }),
    }),

    deleteCasteById: builder.mutation({
      query: ({ id }) => ({
        url: `/caste.php?id=${id}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useAddCasteMutation,
  useDeleteCasteByIdMutation,
  useEditCasteByIdMutation,
  useGetCasteByIdQuery,
  useGetCasteQuery,
} = casteApi;
