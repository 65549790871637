import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const loginUser = createAsyncThunk(
    'auth/login',
    async (payload, { rejectWithValue }) => {
        try {
            const response = await axios.post(
                `${process.env.REACT_APP_API_URL}/adminlogin.php`, payload
            );
            return response.data;
        } catch (error) {
            // If the error is from the server, reject with server error message
            if (error.response) {
                return rejectWithValue(error.response.data);
            } else if (error.request) {
                // If the request was made but no response was received
                return rejectWithValue({ message: 'No response from server' });
            } else {
                // If something else went wrong
                return rejectWithValue({ message: 'Error occurred while processing the request' });
            }
        }
    }
);
