import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";


export const surveyApi = createApi({

    reducerPath: 'surveyApi',

    baseQuery: baseQuery,

    endpoints: builder => ({
        getSurvey: builder.query({
            query: ({ page = 1, pageSize = 10, search = '' }) => ({
                url: `/survey.php?q=${search}&pageSize=${pageSize}&page=${page}`,
                method: 'GET'
            })
        }),
        getAllSurveyList : builder.query({
            query: () => ({
                url: `/surveylist.php`,
                method: 'GET'
            })
        }),
        addSurvey: builder.mutation({
            query: (data) => ({
                url: '/survey.php',
                method: 'POST',
                body: data
            })
        }),
        getSurveyById: builder.query({
            query: ({ id }) => ({
                url: `/survey.php?id=${id}`,
                method: 'GET'
            })
        }),

        editSurveyById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/survey.php?id=${id}`,
                method: 'PUT',
                body: data
            })
        }),

        deleteSurveyById: builder.mutation({
            query: ({ id }) => ({
                url: `/survey.php?id=${id}`,
                method: 'DELETE'
            })
        }),
    })
})

export const { useGetSurveyQuery, useGetAllSurveyListQuery, useGetSurveyByIdQuery, useAddSurveyMutation, useDeleteSurveyByIdMutation, useEditSurveyByIdMutation } = surveyApi;