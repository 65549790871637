import React, { useState } from 'react';
import { Box, Button, Checkbox, CircularProgress, Divider, FormControl, FormControlLabel, Grid, IconButton, MenuItem, Radio, RadioGroup, Select, TextField, Typography } from '@mui/material';


export default function AddQuestionsForm({ data, isSuccess }) {

    const values = data;

    return (

        isSuccess ?
            (
                <Box sx={{ padding: '20px' }}>
                    {
                        values?.question_data?.map((question, index) => (
                            <Box key={index} sx={{ border: '1px solid #ccc', borderRadius: '5px', padding: '15px', marginBottom: '20px' }}>
                                <Grid spacing={2} container>
                                    <Grid item xs={8}>
                                        <TextField
                                            label={`Question ${index + 1}`}
                                            fullWidth
                                            value={question?.question_text}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={4}>
                                        <FormControl fullWidth>
                                            <Select
                                                value={question.question_type.toLowerCase()}
                                                readOnly={true}
                                            >
                                                <MenuItem value="answer">Text Input</MenuItem>
                                                <MenuItem value="radio">Radio</MenuItem>
                                                <MenuItem value="checkbox">Checkbox</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                                {
                                    question.question_type.toLowerCase() === 'answer' && (
                                        <Box sx={{ marginTop: '10px' }}>
                                            {question?.answers?.map((answer, idx) => (
                                                <TextField
                                                    key={idx}
                                                    label={`Answer`}
                                                    fullWidth
                                                    value={answer?.answer_text}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            ))}

                                        </Box>
                                    )
                                }
                                {
                                    question.question_type.toLowerCase() === 'radio' && (
                                        <Box sx={{ marginTop: '10px' }}>
                                            <RadioGroup value={''}>
                                                {question?.options?.map((option, optionIndex) => (
                                                    <FormControlLabel
                                                        key={optionIndex}
                                                        control={<Radio disabled sx={{
                                                            '&.Mui-disabled': {
                                                                color: 'primary.main',
                                                            },
                                                        }} />}
                                                        disableTypography
                                                        label={option.option_text}
                                                    />
                                                ))}
                                            </RadioGroup>

                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px', color: '#4CAF50', textTransform: 'uppercase', borderBottom: '2px solid #4CAF50', display: 'flex', alignItems: 'center', }}>
                                                <span style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#4CAF50">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14l-4-4 1.41-1.41L10 13.17l6.59-6.59L18 8l-8 8z" />
                                                    </svg>
                                                </span>
                                                <span style={{ letterSpacing: '1px' }}>Answers</span>
                                            </Typography>

                                            <RadioGroup>
                                                {question?.answers?.map((answer, idx) => (
                                                    <FormControlLabel
                                                        key={idx}
                                                        control={
                                                            <Radio
                                                                disabled
                                                                sx={{
                                                                    '&.Mui-disabled': {
                                                                        color: 'primary.main',
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        disableTypography
                                                        label={answer.answer_text} // assuming there is an answer_text property in your answer object
                                                        labelPlacement="end" // Example label placement
                                                    />
                                                ))}
                                            </RadioGroup>
                                        </Box>
                                    )
                                }

                                {
                                    question.question_type.toLowerCase() === 'checkbox' && (
                                        <Box sx={{ marginTop: '10px' }}>
                                            {question?.options?.map((option, optionIndex) => (
                                                <Box>
                                                    <FormControlLabel
                                                        key={optionIndex}
                                                        control={<Checkbox disabled sx={{
                                                            '&.Mui-disabled': {
                                                                color: 'primary.main',
                                                            },
                                                        }} />}
                                                        label={option.option_text}
                                                        disableTypography
                                                        InputProps={{
                                                            readOnly: true,
                                                        }}
                                                    />
                                                </Box>
                                            ))}

                                            <Typography variant="subtitle1" sx={{ fontWeight: 'bold', marginTop: '20px', marginBottom: '10px', color: '#4CAF50', textTransform: 'uppercase', borderBottom: '2px solid #4CAF50', display: 'flex', alignItems: 'center', }}>
                                                <span style={{ marginRight: '10px', display: 'flex', alignItems: 'center' }}>
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="#4CAF50">
                                                        <path d="M0 0h24v24H0z" fill="none" />
                                                        <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14l-4-4 1.41-1.41L10 13.17l6.59-6.59L18 8l-8 8z" />
                                                    </svg>
                                                </span>
                                                <span style={{ letterSpacing: '1px' }}>Answers</span>
                                            </Typography>

                                            {question?.answers?.map((answer, idx) => (
                                                <Box>
                                                    
                                                    <FormControlLabel
                                                        key={idx}
                                                        checked={true}
                                                        control={
                                                            <Checkbox
                                                                disabled
                                                                sx={{
                                                                    '&.Mui-disabled': {
                                                                        color: 'primary.main',
                                                                    },
                                                                }}
                                                            />
                                                        }
                                                        disableTypography
                                                        label={answer.answer_text} // assuming there is an answer_text property in your answer object
                                                        labelPlacement="end" // Example label placement
                                                    />
                                                </Box>
                                            ))}

                                        </Box>
                                    )}
                            </Box>
                        ))}
                </Box>
            ) :
            (
                <Box textAlign={"center"}>
                    <CircularProgress />
                </Box>
            )
    );
}
