import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Popper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import TopBar from "../../components/TopBar";
import { Link as LinkRouterDom } from "react-router-dom";
import routeUrls from "../../constants/routeUrls";
import HomeIcon from "@mui/icons-material/Home";
import Chart from "./Chart";
import { useEffect, useRef, useState } from "react";
import { LoadingButton } from "@mui/lab";
import { useGetAllSurveyListQuery } from "../../api/surveyApi";
import { CSVLink } from "react-csv";
import useReports from "./useReports";

export default function SummaryReports() {
  const {
    data: surveyData,
    isSuccess: isSurveySuccess,
    isFetching: isSurveyFetching,
    refetch: surveyRefetch,
  } = useGetAllSurveyListQuery();

  const [selectedSurvey, setSelectedSurvey] = useState("general-survey"); // Default selected value (Survey By Village)

  const [surveyId, setSurveyId] = useState("");
  const [surveyDetail, setSurveyDetail] = useState({});
  const [isSurveyReportsSuccess, setSurveyReportsSuccess] = useState(true);
  const {
    surveyResponseListData,
    isSurveyResponseSuccess,
    surveyResponseListRefetch,
    generatePDF,
    pdfDownloading,
  } = useReports({ surveyId });
  const contentToPrint = useRef(null);
  const handleChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSurvey(selectedValue);
  };

  const breadcrumbs = [
    <Link
      component={LinkRouterDom}
      underline="none"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(103, 58, 183)"
      to={routeUrls.DASHBOARD}
    >
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    </Link>,
    <Typography
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      Reports
    </Typography>,
    <Typography
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      Summary Reports
    </Typography>,
  ];

  useEffect(() => {
    surveyRefetch();
    surveyResponseListRefetch();
  }, []);

  return (
    <>
      <TopBar title={"Summary Reports"} breadcrumbs={breadcrumbs} />

      <Box mt={2}>
        <Chart
          contentToPrint={contentToPrint}
          surveyId={surveyId}
          selectedSurvey={selectedSurvey}
          surveyDetail={surveyDetail}
          setSurveyReportsSuccess={setSurveyReportsSuccess}
          title={
            <Grid container>
              <Grid item xs={12}>
                {surveyId && surveyResponseListData?.length !== 0 && (
                  <CSVLink
                    data={surveyResponseListData}
                    filename={"survey_data.csv"}
                  >
                    <LoadingButton
                      loading={isSurveyResponseSuccess}
                      variant="contained"
                      sx={{
                        textTransform: "none",
                        fontWeight: 600,
                        mb: 1,
                        px: 3,
                      }}
                    >
                      Download Survey Logs
                    </LoadingButton>
                  </CSVLink>
                )}
              </Grid>
              <Grid item xs={12}>
                {surveyId && (
                  <LoadingButton
                    loading={pdfDownloading && isSurveyReportsSuccess}
                    onClick={() => {
                      generatePDF(null, () => contentToPrint.current);
                    }}
                    variant="contained"
                    color="info"
                    sx={{ textTransform: "none", fontWeight: 600, mb: 1 }}
                  >
                    Download Survey Reports
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          }
          action={
            <Grid>
              <Grid item xs={12}>
                <Autocomplete
                  sx={{
                    width: 250,
                    ".MuiInputBase-root": {
                      backgroundColor: "rgb(248, 250, 252)",
                    },
                  }}
                  disablePortal={false}
                  fullWidth
                  id="surveyID"
                  name="surveyID"
                  loading={!isSurveySuccess && isSurveyFetching}
                  options={surveyData?.data || []}
                  getOptionLabel={(survey) => survey?.survey_name || ""} // Display state name
                  value={
                    surveyData?.data?.find(
                      (survey) => survey.id === surveyId
                    ) || null
                  } // Find state object based on stateID
                  onChange={(event, newValue) => {
                    setSurveyId(newValue ? newValue.id : "");
                    setSurveyDetail(newValue || {});
                  }} // Pass state ID
                  renderInput={(params) => (
                    <TextField {...params} label="Survey" />
                  )}
                  PopperComponent={({ children, ...rest }) => (
                    <Popper {...rest} placement="top-start">
                      {children}
                    </Popper>
                  )}
                />
              </Grid>

              <Grid item xs={12} mt={2}>
                {surveyId && (
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Select Survey Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selectedSurvey}
                      label="Select Survey Category"
                      onChange={handleChange}
                    >
                      <MenuItem value="general-survey">General Survey</MenuItem>
                      <MenuItem value="survey-by-village">
                        Survey By Village
                      </MenuItem>
                      <MenuItem value="survey-by-age">Survey By Age</MenuItem>
                      <MenuItem value="survey-by-cast">Survey By Cast</MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Grid>
          }
        />
      </Box>
    </>
  );
}
