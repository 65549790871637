import { useGetSurveyByIdQuery } from "../../../api/surveyApi";


export default function useViewModal({ id }) {

    const { data, isSuccess, isFetching, refetch } = useGetSurveyByIdQuery({ id: id });

    return {
        data,
        isSuccess : (isSuccess && !isFetching),
        refetch
    }
}