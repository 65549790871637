import { useFormik } from 'formik';
import * as yup from 'yup';
import { errorToast, successToast } from '../../../utils/toastService';
import { useEditVillageByIdMutation, useGetVillageByIdQuery } from '../../../api/villageApi';


export default function useEditModal({ id, handleCloseModal }) {

    const [editVillage, { isLoading }] = useEditVillageByIdMutation();
    const { data, isSuccess, refetch, isFetching } = useGetVillageByIdQuery({ id: id });

    const validationSchema = yup.object({
        villageName: yup
            .string()
            .required('Village Name is required'),
        constituencyID: yup
            .number()
            .required('Constituency ID is required'),
    });

    const formik = useFormik({
        initialValues: {
            villageName: data?.name || '',
            constituencyID: data?.constituency_id || 0,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {

            const formData = {
                "constituency_id": values.constituencyID,
                "village": values.villageName
            }

            editVillage({ id: id, data: JSON.stringify(formData) })
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('Village Updated Successfully');
                        formik.resetForm();
                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Updated Village');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Updated Village');
                })
                .finally(() => {
                    handleCloseModal();
                });

        },
    });

    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik,
        isSuccess : (isSuccess && !isFetching),
        refetch,
    }
}