import { Box, Button, Link, Typography } from "@mui/material";
import TopBar from "../../components/TopBar";
import { Link as LinkRouterDom } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import { useDeleteConstituencyByIdMutation, useGetConstituencyQuery } from "../../api/constituencyApi";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../utils/toastService";
import DataTable from "./DataTable";
import AddConstituencyModal from "./AddConstituencyModal";
import ViewConstituencyModal from "./ViewConstituencyModal";
import ConfirmationDialogBox from "../../components/ConfirmationDialogBox";
import EditConstituencyModal from "./EditConstituencyModal";
import routeUrls from "../../constants/routeUrls";

export default function ConstituencyList() {

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [search, setSearch] = useState('');
    const { data, isSuccess, isFetching, refetch } = useGetConstituencyQuery({ page, pageSize, search }, { refetchOnMountOrArgChange: true });
    const [deleteConstituency] = useDeleteConstituencyByIdMutation();

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const handleSearchValue = (value) => {
        setSearch(value);
    }

    const breadcrumbs = [
        <Link
            component={LinkRouterDom}
            underline="none"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="rgb(103, 58, 183)"
            to={routeUrls.DASHBOARD}
        >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>,
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }} color="rgb(105, 117, 134)">
            Survey Location
        </Typography>,
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }} color="rgb(105, 117, 134)">
            Constituency List
        </Typography>
    ];

    const [openAddModal, setOpenAddModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState({
        id: 0,
        open: false
    });
    const [openDeleteModal, setOpenDeleteModal] = useState({
        id: 0,
        open: false
    });
    const [openEditModal, setOpenEditModal] = useState({
        id: 0,
        open: false,
    })

    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    }

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        refetch();
    }

    const handleOpenViewModal = ({ id }) => {
        setOpenViewModal({
            id: id,
            open: true
        });
    }

    const handleCloseViewModal = () => {
        setOpenViewModal({
            id: 0,
            open: false
        });
    }

    const handleOpenDeleteModal = ({ id }) => {
        console.log(id);
        setOpenDeleteModal({
            id: id,
            open: true
        })
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal({
            id: 0,
            open: false
        })
    }

    const handleDeleteConstituency = () => {
        deleteConstituency({ id: openDeleteModal?.id }).then((response) => {
            if (response?.data?.success) {
                successToast('Deleted Constituency Successfully');
                refetch();
            } else {
                errorToast('Unable To Delete Constituency');
            }
        }).catch((error) => {
            errorToast('Unable To Delete Constituency');
        })
        handleCloseDeleteModal();
    }

    const handleOpenEditModal = ({ id }) => {
        setOpenEditModal({
            id: id,
            open: true
        })
    }

    const handleCloseEditModal = () => {
        setOpenEditModal({
            id: 0,
            open: false
        })
        refetch();
    }

    useEffect(() => {
        refetch();
    }, [])

    return (
        <>
            <TopBar title={"Constituency"} breadcrumbs={breadcrumbs} />

            {/* Table */}
            <Box mt={2}>
                <DataTable headers={[
                    '#',
                    'District Name',
                    'Constituency Name',
                    'Actions'
                ]} action={
                    <Button variant="contained" onClick={handleOpenAddModal} sx={{ textTransform: 'none', fontWeight: 600 }}>Add Constituency</Button>
                } data={data?.data} totalPages={data?.pagination?.totalPages} page={page} pageSize={pageSize} handlePageChange={handlePageChange}
                    handleSearchValue={handleSearchValue}
                    handleOpenEditModal={handleOpenEditModal} handleOpenDeleteModal={handleOpenDeleteModal} handleOpenViewModal={handleOpenViewModal} isSuccess={isSuccess && !isFetching}
                />
            </Box>

            <AddConstituencyModal openModal={openAddModal} handleCloseModal={handleCloseAddModal} />

            {
                !!openViewModal?.id && (
                    <ViewConstituencyModal id={openViewModal?.id} openModal={openViewModal?.open} handleCloseModal={handleCloseViewModal} />
                )
            }

            <ConfirmationDialogBox open={openDeleteModal.open} title={"Are you want to delete the constituency?"}
                onAccept={handleDeleteConstituency}
                onReject={handleCloseDeleteModal}
            />

            {
                !!openEditModal?.id && (
                    <EditConstituencyModal id={openEditModal?.id} openModal={openEditModal?.open} handleCloseModal={handleCloseEditModal} />
                )
            }
        </>
    )
}