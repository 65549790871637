import { Box, Button, Link, Typography } from "@mui/material";
import TopBar from "../../components/TopBar";
import { Link as LinkRouterDom } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import { useEffect, useState } from "react";
import DataTable from "./DataTable";
import AddSurveyModal from "./AddSurveyModal";
import { errorToast, successToast } from "../../utils/toastService";
import { useDeleteSurveyByIdMutation, useGetSurveyQuery } from "../../api/surveyApi";
import ConfirmationDialogBox from "../../components/ConfirmationDialogBox";
import EditSurveyModal from "./EditSurveyModal";
import ViewSurveyModal from "./ViewSurveyModal";
import routeUrls from "../../constants/routeUrls";


export default function SurveysList() {

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [search, setSearch] = useState('');

    const { data, isSuccess, isFetching, refetch } = useGetSurveyQuery({ page, pageSize, search }, { refetchOnMountOrArgChange: true });
    const [deleteSurvey] = useDeleteSurveyByIdMutation();

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const handleSearchValue = (value) => {
        setSearch(value);
    }

    const breadcrumbs = [
        <Link
            component={LinkRouterDom}
            underline="none"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="rgb(103, 58, 183)"
            to={routeUrls.DASHBOARD}
        >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>,
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }} color="rgb(105, 117, 134)">
            Surveys
        </Typography>,
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }} color="rgb(105, 117, 134)">
            Surveys List
        </Typography>
    ];

    const [openAddModal, setOpenAddModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState({
        id: 0,
        open: false
    });
    const [openDeleteModal, setOpenDeleteModal] = useState({
        id: 0,
        open: false
    });
    const [openEditModal, setOpenEditModal] = useState({
        id: 0,
        open: false,
    })

    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    }

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        refetch();
    }

    const handleOpenViewModal = ({ id }) => {
        setOpenViewModal({
            id: id,
            open: true
        });
    }

    const handleCloseViewModal = () => {
        setOpenViewModal({
            id: 0,
            open: false
        });
    }

    const handleOpenDeleteModal = ({ id }) => {
        console.log(id);
        setOpenDeleteModal({
            id: id,
            open: true
        })
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal({
            id: 0,
            open: false
        })
    }

    const handleDeleteSurvey = () => {
        deleteSurvey({ id: openDeleteModal?.id }).then((response) => {
            if (response?.data?.success) {
                successToast('Deleted Suvery Successfully');
                refetch();
            } else {
                errorToast('Unable To Delete Survey');
            }
        }).catch((error) => {
            errorToast('Unable To Delete Survey');
        })
        handleCloseDeleteModal();
    }

    const handleOpenEditModal = ({ id }) => {
        setOpenEditModal({
            id: id,
            open: true
        })
    }

    const handleCloseEditModal = () => {
        setOpenEditModal({
            id: 0,
            open: false
        })
        refetch();
    }

    useEffect(() => {
        refetch();
    }, [])
    return (
        <>
            <TopBar title={"Surveys"} breadcrumbs={breadcrumbs} />

            {/* Table */}
            <Box mt={2}>
                <DataTable headers={[
                    '#',
                    'Survey Name',
                    'State',
                    'District',
                    'Constituency',
                    'Created At',
                    'Updated At',
                    'Actions'
                ]} action={
                    <Button variant="contained" onClick={handleOpenAddModal} sx={{ textTransform: 'none', fontWeight: 600 }}>Add Survey</Button>
                } data={data?.data} totalPages={data?.pagination?.totalPages} page={page} pageSize={pageSize} handlePageChange={handlePageChange}
                    handleSearchValue={handleSearchValue}
                    handleOpenEditModal={handleOpenEditModal} handleOpenDeleteModal={handleOpenDeleteModal} handleOpenViewModal={handleOpenViewModal} isSuccess={isSuccess && !isFetching} />
            </Box>

            <AddSurveyModal openModal={openAddModal} handleCloseModal={handleCloseAddModal} />

            {
                !!openViewModal?.id && (
                    <ViewSurveyModal id={openViewModal?.id} openModal={openViewModal?.open} handleCloseModal={handleCloseViewModal} />
                )
            }

            <ConfirmationDialogBox open={openDeleteModal.open} title={"Are you want to delete the survey?"}
                onAccept={handleDeleteSurvey}
                onReject={handleCloseDeleteModal}
            />
            {
                !!openEditModal?.id && (
                    <EditSurveyModal id={openEditModal?.id} openModal={openEditModal?.open} handleCloseModal={handleCloseEditModal} />
                )
            }
        </>
    )
}