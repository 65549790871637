import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useViewModal from "./useViewModal";
import { useEffect } from "react";
import { useGetDistrictQuery } from "../../../api/districtApi";

export default function ViewConstituencyModal({ id, openModal, handleCloseModal }) {

    const { data, refetch, isSuccess } = useViewModal({ id });

    const { data: districtData, isSuccess: isDistrictSuccess, refetch: districtRefetch } = useGetDistrictQuery({ page: 1, pageSize: 10000 });

    useEffect(() => {
        refetch();
        districtRefetch();
    }, [])

    return (
        <>
            <Dialog fullWidth sx={{
                '.MuiDialog-paper': {
                    borderRadius: 4
                }
            }} open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id="customized-dialog-title">
                    District Details
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {
                        isSuccess ?
                            (
                                <Grid container spacing={3}>
                                    <Grid item xs={12}>
                                        <TextField
                                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                            fullWidth
                                            id="constituencyName"
                                            name="constituencyName"
                                            label="Constituency"
                                            value={data?.name}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                            fullWidth
                                            id="districtName"
                                            name="districtName"
                                            label="District"
                                            value={districtData?.data?.find(district => district.id == data?.district_id)?.district_name}
                                            InputProps={{
                                                readOnly: true,
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            ) :
                            (
                                <Box textAlign={"center"}>
                                    <CircularProgress />
                                </Box>
                            )
                    }
                </DialogContent>
            </Dialog>
        </>
    )
}