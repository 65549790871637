import { Box, Button, Dialog, DialogTitle, Typography } from "@mui/material";

export default function ConfirmationDialogBox({ open, title, onAccept, onReject }) {
    return (
        <>
            <Dialog maxWidth='xs' fullWidth open={open} disableEscapeKeyDown PaperProps={{
                sx: {
                    borderRadius: 5
                }
            }} >
                <Box p={2}>
                    <DialogTitle>
                        {title && (
                            <Typography variant='h4' textAlign='center' color='primary'>
                                {title}
                            </Typography>
                        )}
                    </DialogTitle>

                    <Box p={2}>
                        <Box>
                            <Button fullWidth type='button' variant='contained' color='primary' onClick={onAccept}>
                                Yes
                            </Button>
                        </Box>
                        <Box mt={2}>
                            <Button type='button' variant='outlined' color='primary' fullWidth onClick={onReject}>
                                No
                            </Button>
                        </Box>
                    </Box>
                </Box>
            </Dialog>
        </>
    )
}