import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAddSurveyMutation } from '../../../api/surveyApi';
import { errorToast, successToast } from '../../../utils/toastService';

export default function useAddModal({ handleCloseModal }) {

    const [addSurvey, { isLoading }] = useAddSurveyMutation();

    const validationSchema = yup.object({
        surveyname: yup.string().required('Survey Name is required'),
        stateID: yup.number().required('State is required'),
        districtID: yup.number().required('District is required'),
        constituencyID: yup.number().required('Vidhan Sabha Constituency is required'),
    });

    const formik = useFormik({
        initialValues: {
            surveyname: '',
            stateID: 0,
            districtID: 0,
            constituencyID: 0,
            questions: []
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const formData = new FormData();
            formData.append("survey_name", values.surveyname);
            formData.append("state_id", values.stateID);
            formData.append("district_id", values.districtID);
            formData.append("constituency_id", values.constituencyID);

           
            values.questions.forEach((question, index) => {
                formData.append(`questions[${index}][question_text]`, question.label);
                formData.append(`questions[${index}][question_type]`, question.type);
                question.options.forEach((option, optionIndex) => {
                    formData.append(`questions[${index}][options][${optionIndex}][option_text]`, option);
                });
            });
            
            addSurvey(formData)
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('Survey Added Successfully');
                        formik.resetForm();

                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Add Survey');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Add Survey');
                })
                .finally(() => {
                    handleCloseModal();
                });
        },
    });

    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik
    }
}