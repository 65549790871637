import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";


export const stateApi = createApi({

    reducerPath: 'stateApi',

    baseQuery: baseQuery,

    endpoints: builder => ({
        getState: builder.query({
            query: ({ page = 1, pageSize = 10, search = '' }) => ({
                url: `/state.php?q=${search}&pageSize=${pageSize}&page=${page}`,
                method: 'GET'
            })
        }),
        addState: builder.mutation({
            query: (data) => ({
                url: '/state.php',
                method: 'POST',
                body: data
            })
        }),
        getStateById: builder.query({
            query: ({ id }) => ({
                url: `/state.php?id=${id}`,
                method: 'GET'
            })
        }),

        editStateById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/state.php?id=${id}`,
                method: 'PUT',
                body: data
            })
        }),

        deleteStateById: builder.mutation({
            query: ({ id }) => ({
                url: `/state.php?id=${id}`,
                method: 'DELETE'
            })
        }),
    })
})

export const { useGetStateQuery, useGetStateByIdQuery, useAddStateMutation, useDeleteStateByIdMutation, useEditStateByIdMutation } = stateApi;