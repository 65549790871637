import { Box, Typography } from "@mui/material";
import DataTable from "./DataTable";

export default function SurveyByVillage({ formData, getTotalCount }) {
    return (
        <>
            {
                formData?.length ? (
                    <Box px={3} id="pdf-content">
                        {
                            formData?.map((data, dataIndex) => (
                                <Box key={dataIndex}>
                                    {
                                        dataIndex !== 0 && <Box className="page-break" sx={{display: "block",
                                            pageBreakBefore: 'always'}}></Box>
                                    }
                                    <Box mt={5}>
                                        <Typography variant="h6" fontWeight={"bold"} textAlign={"center"} mb={5}>
                                            Village: {data?.village || "N/A"}
                                        </Typography>

                                        {
                                            data?.questions?.map((question, questionIndex) => (
                                                <Box key={questionIndex}>
                                                    <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold', mt: 4 }}>
                                                        Q{questionIndex + 1}: {question.question_text}
                                                    </Typography>
                                                    <DataTable headers={[
                                                        '#',
                                                        'Option Name',
                                                        'Total Count',
                                                        'Percentage'
                                                    ]} data={question?.options} totalCount={getTotalCount(question)} />
                                                </Box>
                                            ))
                                        }
                                    </Box>
                                </Box>
                            ))
                        }
                    </Box>
                ) : (
                    <Box textAlign="center" p={2} id="pdf-content">No Records Found</Box>
                )
            }
        </>
    )
}