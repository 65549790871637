import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";

export const reportsApi = createApi({
  reducerPath: "reportsApi",

  baseQuery: baseQuery,

  endpoints: (builder) => ({
    getUserReports: builder.query({
      query: () => ({
        url: `/userReports.php`,
        method: "GET",
      }),
    }),
    getSummaryReportsByVillage: builder.query({
      query: ({ id }) => ({
        url: `/data.php?id=${id}`,
        method: "GET",
      }),
    }),
    getSummaryReportsByAge: builder.query({
      query: ({ id }) => ({
        url: `/dataAge.php?id=${id}`,
        method: "GET",
      }),
    }),
    getSummaryReportsByCaste: builder.query({
      query: ({ id }) => ({
        url: `/dataCaste.php?id=${id}`,
        method: "GET",
      }),
    }),
    getAllSurveyResponseLists: builder.query({
      query: ({ id }) => ({
        url: `/getData.php?id=${id}`,
        method: "GET",
      }),
    }),
    getGeneralSummaryReports: builder.query({
      query: ({ id }) => ({
        url: `/response.php?id=${id}`,
        method: "GET",
      }),
    }),
    getUserStatistics: builder.query({
      query: ({ id }) => ({
        url: `/responseCount.php?surveyId=${id}`,
        method: "GET",
      }),
    }),
    getSurveyDetails: builder.query({
      query: ({ id }) => ({
        url: `/getDetails.php?id=${id}`,
        method: "GET",
      }),
    }),
    getMapDetails: builder.query({
      query: () => ({
        url: `/getAllDetails.php`,
        method: "GET",
      }),
    }),
  }),
});

export const {
  useGetUserReportsQuery,
  useGetSummaryReportsByAgeQuery,
  useGetSummaryReportsByCasteQuery,
  useGetGeneralSummaryReportsQuery,
  useGetAllSurveyResponseListsQuery,
  useGetSummaryReportsByVillageQuery,
  useLazyGetUserStatisticsQuery,
  useGetSurveyDetailsQuery,
  useGetMapDetailsQuery,
} = reportsApi;
