import { Box, Breadcrumbs, Card, CardContent, Grid, Stack, Typography } from "@mui/material";
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

export default function TopBar({ title, breadcrumbs }) {

    return (
        <>
            <Card elevation={0} sx={{ borderRadius: 3 }}>
                <CardContent style={{ paddingBottom: '16px' }}>
                    <Grid container>
                        <Grid item xs={6}>
                            <Typography variant="h6" fontWeight={600}>
                                {title}
                            </Typography>
                        </Grid>
                        <Grid item xs={6} alignSelf={"center"}>
                            <Stack spacing={2} alignItems="flex-end">
                                <Breadcrumbs separator={<ChevronRightIcon fontSize="small"/>}>
                                    {breadcrumbs}
                                </Breadcrumbs>
                            </Stack>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}