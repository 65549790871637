import {
  Box,
  Card,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
} from "@mui/material";

import BasicDetails from "./BasicDetails";
import useChart from "./useChart";
import { useEffect } from "react";
import UserCountByVillage from "./UserCountByVillage";

export default function Chart({
  surveyId,
  surveyDetail,
  setSurveyReportsSuccess,
  title,
  action,
  selectedSurvey,
  contentToPrint,
}) {
  const {
    triggerUserStatistics,
    formData,
    refetch,
    isFormFetching,
    isFormSuccess,
  } = useChart({
    surveyId,
  });

  useEffect(() => {
    if (surveyId) {
      triggerUserStatistics({ id: surveyId });
    }
  }, [surveyId]);

  return (
    <>
      <Card elevation={0} sx={{ borderRadius: 3 }}>
        <CardHeader
          sx={{
            ".MuiCardHeader-action": { alignSelf: "center", mr: 1 },
          }}
          title={title}
          action={action}
        />
        <Divider />
        <CardContent ref={contentToPrint} style={{ paddingBottom: "16px" }}>
          {surveyId ? (
            <>
              {isFormSuccess && !isFormFetching ? (
                <Box>
                  <BasicDetails data={surveyDetail} />
                  <Divider sx={{ my: 2 }} />
                  <UserCountByVillage formData={formData} />
                </Box>
              ) : (
                <Box textAlign="center" p={2}>
                  <CircularProgress />
                </Box>
              )}
            </>
          ) : (
            <Box textAlign="center" p={2}>
              Please Select a Survey to View the Chart
            </Box>
          )}
        </CardContent>
      </Card>
    </>
  );
}
