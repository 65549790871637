import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { errorToast, successToast } from '../../../utils/toastService';
import { useEditUserByIdMutation, useGetUserByIdQuery } from '../../../api/userApi';


export default function useEditModal({ id, handleCloseModal }) {

    const [editUser, { isLoading }] = useEditUserByIdMutation();
    const { data, isSuccess, isFetching, refetch } = useGetUserByIdQuery({ id: id });

    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const validationSchema = yup.object({
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string()
            .required('Password is required'),
        confirmpassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match') // Ensure confirm password matches password
            .required('Confirm password is required'),
    });


    const formik = useFormik({
        initialValues: {
            email: data?.username || '',
            password: data?.password || '',
            confirmpassword: data?.password || ''
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const formData = {
                "username": values?.email,
                "password": values?.password
            }
            editUser({id: id, data: JSON.stringify(formData)})
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('User Updated Successfully')
                        formik.resetForm();
                    } else if (response?.error) {
                        console.log(response);
                        errorToast(response?.error?.data?.message)
                    } else {
                        errorToast('Unable To Update User');
                    }
                    handleCloseModal();
                })
                .catch((error) => {
                    errorToast('Unable To Update User');
                    handleCloseModal();
                });
        },
    });

    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        showPassword, handleClickShowPassword,
        showConfirmPassword, handleClickShowConfirmPassword,
        isSuccess : (isSuccess && !isFetching),
        formik,
        refetch
    }
}