import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Popper, Step, StepLabel, Stepper, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import useEditModal from "./useEditModal";
import { useEffect, useState } from "react";
import SurveyBasicDetailsForm from "./SurveyBasicDetailsForm";
import SubmitSurveyForm from "./SubmitSurveyForm";
import AddQuestionsForm from "./AddQuestionsForm";

export default function EditSurveyModal({ id, openModal, handleCloseModal }){

    const { formik, refetch, isLoading, isSuccess, handleSubmit  } = useEditModal({ id, handleCloseModal });

    useEffect(() => {
        refetch();
    }, [])

    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Survey Basic Details', 'Add Questions', 'Submit Survey'];

    const handleNext = () => {
        if(activeStep==2){
            return;
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleSubmitForm = () => {
        handleSubmit();
    }

    return (
        <Dialog fullWidth maxWidth={"md"} open={openModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
            <DialogTitle sx={{ textAlign: 'center' }}>Edit Survey</DialogTitle>
            <IconButton aria-label="close" onClick={handleCloseModal} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                <CloseIcon />
            </IconButton>

            <Stepper sx={{ mb: 2 }} activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <DialogContent dividers>
                {activeStep === 0 && <SurveyBasicDetailsForm formik={formik} isSuccess={isSuccess}/>}
                {activeStep === 1 && <AddQuestionsForm formik={formik} isSuccess={isSuccess}/>}
                {activeStep === 2 && <SubmitSurveyForm formik={formik} isLoading={isLoading} handleSubmit={handleSubmitForm}/>}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseModal}>Cancel</Button>
                <Button onClick={handleBack} disabled={activeStep === 0}>Back</Button>
                <Button onClick={handleNext} disabled={activeStep === 2} variant="contained" color="primary">
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
}