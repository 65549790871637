import routeUrls from "../../constants/routeUrls";
import LoginLayout from "../../layouts/LoginLayout";
import Login from "../../pages/Login";

const PublicRoutes = {
    path: routeUrls.LOGIN,
    Component: LoginLayout,
    children: [
        { index: true, Component: Login },
    ],
}

export default PublicRoutes;