import { Grid } from "@mui/material";
import DashboardCard from "../../components/Cards/DashboardCard";
import PollTwoToneIcon from '@mui/icons-material/PollTwoTone';
import CheckCircleTwoToneIcon from '@mui/icons-material/CheckCircleTwoTone';
import HourglassEmptyTwoToneIcon from '@mui/icons-material/HourglassEmptyTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import LocationCityTwoToneIcon from '@mui/icons-material/LocationCityTwoTone';
import AccountBalanceTwoToneIcon from '@mui/icons-material/AccountBalanceTwoTone';
import PublicTwoToneIcon from '@mui/icons-material/PublicTwoTone';
import HomeTwoToneIcon from '@mui/icons-material/HomeTwoTone'; // New icon for villages
import useDashboard from "./useDashboard";

export default function Dashboard() {

    const { totalSurvey, totalVillage, totalConstituency, totalDistrict, totalState, totalUser, totalCompleteSurvey, totalOngoingSurvey } = useDashboard();

    return (
        <>
            <Grid container spacing={2}>
                <Grid item xs={12} lg={4}>
                    <DashboardCard
                        title={"Total Surveys"}
                        subtitle={"Number of all surveys in the system"}
                        color={"rgb(103, 58, 183)"}
                        count={totalSurvey?.success ? totalSurvey?.count : '----'}
                        icon={<PollTwoToneIcon sx={{ opacity: 0.7, fontSize: '80px' }} />}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DashboardCard
                        title={"Completed Surveys"}
                        subtitle={"Number of surveys completed by users"}
                        color={"rgb(30, 136, 229)"}
                        count={totalCompleteSurvey?.success ? totalCompleteSurvey?.count : '----'}
                        icon={<CheckCircleTwoToneIcon sx={{ opacity: 0.7, fontSize: '80px' }} />}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DashboardCard
                        title={"Ongoing Surveys"}
                        subtitle={"Number of surveys currently active"}
                        color={"rgb(216, 67, 21)"}
                        count={totalOngoingSurvey?.success ? totalOngoingSurvey?.count : '----'}
                        icon={<HourglassEmptyTwoToneIcon sx={{ opacity: 0.7, fontSize: '80px' }} />}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DashboardCard
                        title={"Total Users"}
                        subtitle={"Number of registered users"}
                        color={"rgb(0, 150, 136)"}
                        count={totalUser?.success ? totalUser?.count : '----'}
                        icon={<PeopleAltTwoToneIcon sx={{ opacity: 0.7, fontSize: '80px' }} />}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DashboardCard
                        title={"Total States"}
                        subtitle={"Number of states in the system"}
                        color={"rgb(63, 81, 181)"}
                        count={totalState?.success ? totalState?.count : '----'}
                        icon={<PublicTwoToneIcon sx={{ opacity: 0.7, fontSize: '80px' }} />}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DashboardCard
                        title={"Total Districts"}
                        subtitle={"Number of districts in the system"}
                        color={"rgb(233, 30, 99)"}
                        count={totalDistrict?.success ? totalDistrict?.count : '----'}
                        icon={<LocationCityTwoToneIcon sx={{ opacity: 0.7, fontSize: '80px' }} />}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DashboardCard
                        title={"Total Constituencies"}
                        subtitle={"Number of constituencies in the system"}
                        color={"rgb(33, 150, 243)"}
                        count={totalConstituency?.success ? totalConstituency?.count : '----'}
                        icon={<AccountBalanceTwoToneIcon sx={{ opacity: 0.7, fontSize: '80px' }} />}
                    />
                </Grid>
                <Grid item xs={12} lg={4}>
                    <DashboardCard
                        title={"Total Villages"}
                        subtitle={"Number of villages in the system"}
                        color={"rgb(76, 175, 80)"}
                        count={totalVillage?.success ? totalVillage?.count : '----'}
                        icon={<HomeTwoToneIcon sx={{ opacity: 0.7, fontSize: '80px' }} />}
                    />
                </Grid>
            </Grid>

        </>
    )
}