import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import useEditModal from "./useEditModal";
import { useEffect } from "react";

export default function EditStateModal({ id, openModal, handleCloseModal }) {

    const { formik, refetch, isLoading, isSuccess, handleSubmit } = useEditModal({ id, handleCloseModal });

    useEffect(()=>{
        refetch();
    },[])

    return (
        <>
            <Dialog fullWidth sx={{
                '.MuiDialog-paper': {
                    borderRadius: 4
                }
            }} open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id="customized-dialog-title">
                    Edit State
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {
                        isSuccess ?
                            (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                                fullWidth
                                                id="statename"
                                                name="statename"
                                                label="State"
                                                required
                                                value={formik.values.statename}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.statename && Boolean(formik.errors.statename)}
                                                helperText={formik.touched.statename && formik.errors.statename}
                                            />
                                        </Grid>
                                    </Grid>
                                </form>
                            ) :
                            (
                                <Box textAlign={"center"}>
                                    <CircularProgress />
                                </Box>
                            )
                    }

                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" sx={{
                        backgroundColor: (theme) => theme.palette.cancel.main,
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.cancel.main, // Adjust hover color if needed
                        }, textTransform: 'none'
                    }} onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading || !isSuccess} disabled={!formik.isValid} variant="contained" sx={{ textTransform: 'none' }} autoFocus onClick={handleSubmit}>
                        Update
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}