import { useGetVillageByIdQuery } from "../../../api/villageApi";

export default function useViewModal({ id }) {

    const { data, isSuccess, isFetching, refetch } = useGetVillageByIdQuery({ id: id });

    return {
        data,
        isSuccess : (isSuccess && !isFetching),
        refetch
    }
}