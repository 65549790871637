import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';

export default function SearchBar({ handleSearchValue }) {
    
    const debouncedHandleSearchValue = debounce(handleSearchValue, 1000);

    const handleChange = (event) => {
        const { value } = event.target;
        // Call the debounced handleSearchValue function with the input value
        debouncedHandleSearchValue(value);
    };

    return (
        <>
            <TextField
                id="search-bar"
                placeholder="Search"
                onChange={handleChange}
                sx={{
                    m: 1,
                    width: '18ch',
                    '.MuiInputBase-root': {
                        borderRadius: 3,
                        background: 'rgb(248, 250, 252)'
                    },
                    '.MuiInputBase-input':{
                        padding: '10px 14px',
                        paddingLeft: '5px',
                        background: 'rgb(248, 250, 252)'
                    }
                }}
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        <SearchIcon fontSize="small"/>
                    </InputAdornment>,
                }}
            />
        </>
    );
}
