import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { errorToast, successToast } from '../../../utils/toastService';
import { useAddAssignSurveyMutation } from '../../../api/assignSurveyApi';


export default function useAddModal({ handleCloseModal, handleUpdateCallback }) {

    const [assignSurvey, { isLoading }] = useAddAssignSurveyMutation();

    const validationSchema = yup.object({
        userID: yup
            .number()
            .required('User is required'),
        surveyID: yup
            .number()
            .required('Survey is required'),
    });

    const formik = useFormik({
        initialValues: {
            userID: 0,
            surveyID: 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const formData = new FormData();
            formData.append("user_id",values.userID);
            formData.append("survey_id",values.surveyID);

            assignSurvey(formData)
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('Assigned Survey Successfully');
                        formik.resetForm();
                        handleUpdateCallback();
                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Assigned Survey');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Assigned Survey');
                })
                .finally(() => {
                    handleCloseModal();
                });
        },
    });


    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik
    }
}