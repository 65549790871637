import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { errorToast, successToast } from '../../../utils/toastService';
import { useAddStateMutation } from '../../../api/stateApi';


export default function useAddModal({handleCloseModal}) {

    const [addState, { isLoading }] = useAddStateMutation();

    const validationSchema = yup.object({
        statename: yup
            .string()
            .required('State name is required'),
    });


    const formik = useFormik({
        initialValues: {
            statename: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const formData = new FormData();
            formData.append("state", values?.statename);
            
            addState(formData)
            .unwrap()
            .then((response) => {
                if (response?.success) {
                    successToast('State Added Successfully')
                    formik.resetForm();
                } else if (response.error) {
                    errorToast(response?.error?.data?.message)
                }
            })
            .catch((error) => {
                errorToast('Unable To Add State')
            })
            .finally(()=>{
                handleCloseModal();
            });
        },
    });

    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik
    }
}