import { Box, Button, Link, Typography } from "@mui/material";
import TopBar from "../../components/TopBar";
import HomeIcon from '@mui/icons-material/Home';
import { Link as LinkRouterDom } from "react-router-dom";
import DataTable from "./DataTable";
import { useEffect, useState } from "react";
import { useDeleteAssignSurveyByIdMutation, useGetAssignSurveyQuery } from "../../api/assignSurveyApi";
import { errorToast, successToast } from "../../utils/toastService";
import AddAssignSurveyModal from "./AddAssignSurveyModal";
import ConfirmationDialogBox from "../../components/ConfirmationDialogBox";
import ViewAssignSurveyModal from "./ViewAssignSurveyModal";
import EditAssignSurveyModal from "./EditAssignSurveyModal";
import ViewResponseDetailModal from "./ViewResponseDetailModal";
import routeUrls from "../../constants/routeUrls";
import { useGetAssignUserQuery } from "../../api/userApi";
import { useGetSurveyQuery } from "../../api/surveyApi";

export default function AssignSurvey() {

    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(5);
    const [search, setSearch] = useState('');
    const { data, isSuccess, isFetching, refetch } = useGetAssignSurveyQuery({ page, pageSize, search }, { refetchOnMountOrArgChange: true });
    const [deleteAssignSurvey] = useDeleteAssignSurveyByIdMutation();

    const { data: userData, isSuccess: isUserSuccess, isFetching: isUserFetching, refetch: userRefetch } = useGetAssignUserQuery();
    const { data: surveyData, isSuccess: isSurveySuccess, isFetching: isSurveyFetching, refetch: surveyRefetch } = useGetSurveyQuery({ page: 1, pageSize: 100000 });

    const handlePageChange = (event, value) => {
        setPage(value);
    }

    const handleSearchValue = (value) => {
        setSearch(value);
    }

    const breadcrumbs = [
        <Link
            component={LinkRouterDom}
            underline="none"
            sx={{ display: 'flex', alignItems: 'center' }}
            color="rgb(103, 58, 183)"
            to={routeUrls.DASHBOARD}
        >
            <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
        </Link>,
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }} color="rgb(105, 117, 134)">
            Surveys
        </Typography>,
        <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }} color="rgb(105, 117, 134)">
            Assign Survey
        </Typography>
    ];

    const [openAddModal, setOpenAddModal] = useState(false);
    const [openViewModal, setOpenViewModal] = useState({
        id: 0,
        open: false
    });
    const [openDeleteModal, setOpenDeleteModal] = useState({
        id: 0,
        open: false
    });
    const [openEditModal, setOpenEditModal] = useState({
        id: 0,
        open: false,
    })

    const [openResponseDetailModal, setOpenResponseDetailModal] = useState({
        id: 0,
        open: false,
    })

    const handleOpenAddModal = () => {
        setOpenAddModal(true);
    }

    const handleCloseAddModal = () => {
        setOpenAddModal(false);
        refetch();
    }

    const handleOpenViewModal = ({ id }) => {
        setOpenViewModal({
            id: id,
            open: true
        });
    }

    const handleCloseViewModal = () => {
        setOpenViewModal({
            id: 0,
            open: false
        });
    }

    const handleOpenDeleteModal = ({ id }) => {
        setOpenDeleteModal({
            id: id,
            open: true
        })
    }

    const handleCloseDeleteModal = () => {
        setOpenDeleteModal({
            id: 0,
            open: false
        })
    }

    const handleOpenResponseDetailModal = ({ id }) => {
        setOpenResponseDetailModal({
            id: id,
            open: true
        })
    }

    const handleCloseResponseDetailModal = () => {
        setOpenResponseDetailModal({
            id: 0,
            open: false
        })
    }

    const handleDeleteAssignSurvey = () => {
        deleteAssignSurvey({ id: openDeleteModal?.id }).then((response) => {
            if (response?.data?.success) {
                successToast('Completed Assigned Survey');
                handleUpdateCallback();
            } else {
                errorToast('Failed to Complete Assigned Survey');
            }
        }).catch((error) => {
            errorToast('Failed to Complete Assigned Survey');
        })
        handleCloseDeleteModal();
    }
    

    const handleOpenEditModal = ({ id }) => {
        setOpenEditModal({
            id: id,
            open: true
        })
    }

    const handleCloseEditModal = () => {
        setOpenEditModal({
            id: 0,
            open: false
        })
        refetch();
    }

    const handleUpdateCallback = () => {
        refetch();
        userRefetch();
        surveyRefetch();
    }

    useEffect(() => {
        refetch();
        userRefetch();
        surveyRefetch();
    }, [])

    return (
        <>
            <TopBar title={"Assign Survey"} breadcrumbs={breadcrumbs} />

            <Box mt={2}>
                <DataTable headers={[
                    '#',
                    'Survey Name',
                    'Assigned User',
                    'Status',
                    'Mark Complete',
                    'Created At',
                    'Updated At',
                    'Actions'
                ]} action={
                    <Button variant="contained" onClick={handleOpenAddModal} sx={{ textTransform: 'none', fontWeight: 600 }}>Assign Survey</Button>
                } data={data?.data} totalPages={data?.pagination?.totalPages} page={page} pageSize={pageSize} handlePageChange={handlePageChange}
                    handleSearchValue={handleSearchValue}
                    handleOpenResponseDetailModal={handleOpenResponseDetailModal}
                    handleOpenEditModal={handleOpenEditModal} handleOpenDeleteModal={handleOpenDeleteModal} handleOpenViewModal={handleOpenViewModal} isSuccess={isSuccess && !isFetching} />
            </Box>

            <AddAssignSurveyModal userData={userData} isUserSuccess={isUserSuccess && !isUserFetching}
             surveyData={surveyData} isSurveySuccess={isSurveySuccess && !isSurveyFetching}
            openModal={openAddModal} handleCloseModal={handleCloseAddModal} handleUpdateCallback={handleUpdateCallback}/>

            {
                !!openViewModal?.id && (
                    <ViewAssignSurveyModal id={openViewModal?.id} openModal={openViewModal?.open} handleCloseModal={handleCloseViewModal} />
                )
            }

            <ConfirmationDialogBox open={openDeleteModal.open} title={"Are you want to complete the assigned survey?"}
                onAccept={handleDeleteAssignSurvey}
                onReject={handleCloseDeleteModal}
            />

            {
                !!openEditModal?.id && (
                    <EditAssignSurveyModal userData={userData} isUserSuccess={isUserSuccess && !isUserFetching}
                    surveyData={surveyData} isSurveySuccess={isSurveySuccess && !isSurveyFetching} handleUpdateCallback={handleUpdateCallback} id={openEditModal?.id} openModal={openEditModal?.open} handleCloseModal={handleCloseEditModal} />
                )
            }
            {
                !!openResponseDetailModal?.id && (
                    <ViewResponseDetailModal id={openResponseDetailModal?.id} openModal={openResponseDetailModal?.open} handleCloseModal={handleCloseResponseDetailModal} />
                )
            }
        </>
    )
}