import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Box, CssBaseline, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Paper, TextField, Typography } from "@mui/material";
import useLoginHook from "./useLoginHook";
import { LoadingButton } from "@mui/lab";
import LoginImage from '../../assets/images/loginImage.jpg';

export default function Login() {

    const { formik, loading, showPassword, handleClickShowPassword, handleSubmit } = useLoginHook();

    return (
        <>
            <Grid container component="main" sx={{ height: '100vh' }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    md={7}
                    sx={{
                        backgroundImage: `url(${LoginImage})`,
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }}
                />
                <Grid item xs={12} md={5} component={Paper} elevation={6} square>
                    <Box
                        sx={{
                            mt: 14,
                            mx: 4,
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }}
                    >
                        <Typography variant="h4" fontWeight={"bold"} color={'primary.main'} gutterBottom>
                            Welcome Back!
                        </Typography>

                        <Typography variant="h6" color={'rgb(105, 117, 134)'}>
                            Login to Continue
                        </Typography>

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                            <TextField
                                fullWidth
                                margin="normal"
                                id="email"
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email}
                            />
                            <FormControl variant="outlined" fullWidth margin="normal">
                                <InputLabel htmlFor="password">Password</InputLabel>
                                <OutlinedInput
                                    id="password"
                                    name="password"
                                    label="Password"
                                    type={showPassword ? 'text' : 'password'}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label="toggle password visibility"
                                                onClick={handleClickShowPassword}
                                                edge="end"
                                            >
                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                />
                                {formik.touched.password && formik.errors.password && (
                                    <FormHelperText error>{formik.errors.password}</FormHelperText>
                                )}
                            </FormControl>

                            <LoadingButton
                                type="submit"
                                fullWidth
                                loading={loading}
                                variant="contained"
                                sx={{ mt: 3, mb: 2, py: 1 }}
                            >
                                <Typography fontWeight={"600"}>
                                    Sign In
                                </Typography>
                            </LoadingButton>
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </>
    )
}