import { Divider, List, ListSubheader } from "@mui/material";
import DashboardCustomizeOutlinedIcon from "@mui/icons-material/DashboardCustomizeOutlined";
import PeopleOutlineOutlinedIcon from "@mui/icons-material/PeopleOutlineOutlined";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import PersonIcon from "@mui/icons-material/Person";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import PollOutlinedIcon from "@mui/icons-material/PollOutlined";
import PublicOutlinedIcon from "@mui/icons-material/PublicOutlined";
import LocationCityOutlinedIcon from "@mui/icons-material/LocationCityOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import MenuListItem from "../MenuListItem";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import MapIcon from "@mui/icons-material/Map";
import routeUrls from "../../../../constants/routeUrls";

export default function MenuList({ open }) {
  return (
    <>
      <List
        sx={{
          pb: 1,
        }}
        subheader={
          open && (
            <ListSubheader
              component="div"
              sx={{ ml: 1, color: "black", fontWeight: 600 }}
              id="nested-list-subheader"
            >
              Overview
            </ListSubheader>
          )
        }
      >
        <MenuListItem
          open={open}
          title={"Dashboard"}
          icon={<DashboardCustomizeOutlinedIcon fontSize="small" />}
          to={routeUrls.DASHBOARD}
        />
        <MenuListItem
          open={open}
          title={"Users"}
          icon={<PeopleOutlineOutlinedIcon />}
          to={routeUrls.USERS}
        />
      </List>

      {open && <Divider sx={{ mx: 2 }} />}

      <List
        sx={{
          pt: 0,
          pb: open ? 1 : 0,
        }}
        subheader={
          open && (
            <ListSubheader
              component="div"
              sx={{ ml: 1, color: "black", fontWeight: 600 }}
              id="nested-list-subheader"
            >
              Survey Location
            </ListSubheader>
          )
        }
      >
        <MenuListItem
          open={open}
          title={"State List"}
          icon={<PublicOutlinedIcon />}
          to={routeUrls.STATELIST}
        />
        <MenuListItem
          open={open}
          title={"District List"}
          icon={<LocationCityOutlinedIcon />}
          to={routeUrls.DISTRICTLIST}
        />
        <MenuListItem
          open={open}
          title={"Constituency List"}
          icon={<AccountBalanceOutlinedIcon />}
          to={routeUrls.CONSTITUENCYLIST}
        />
        <MenuListItem
          open={open}
          title={"Village List"}
          icon={<HomeOutlinedIcon />}
          to={routeUrls.VILLAGELIST}
        />
        <MenuListItem
          open={open}
          title={"Caste List"}
          icon={<PersonIcon />}
          to={routeUrls.CASTELIST}
        />
      </List>

      {open && <Divider sx={{ mx: 2 }} />}

      <List
        sx={{
          pt: 0,
          pb: open ? 1 : 0,
        }}
        subheader={
          open && (
            <ListSubheader
              component="div"
              sx={{ ml: 1, color: "black", fontWeight: 600 }}
              id="nested-list-subheader"
            >
              Surveys
            </ListSubheader>
          )
        }
      >
        <MenuListItem
          open={open}
          title={"Surveys List"}
          icon={<PollOutlinedIcon />}
          to={routeUrls.SURVEYSLIST}
        />
        <MenuListItem
          open={open}
          title={"Assign Survey"}
          icon={<AssignmentOutlinedIcon />}
          to={routeUrls.ASSIGNSURVEY}
        />
      </List>

      {open && <Divider sx={{ mx: 2 }} />}
      <List
        sx={{
          pt: 0,
        }}
        subheader={
          open && (
            <ListSubheader
              component="div"
              sx={{ ml: 1, color: "black", fontWeight: 600 }}
              id="nested-list-subheader"
            >
              Reports
            </ListSubheader>
          )
        }
      >
        <MenuListItem
          open={open}
          title={"Summary Reports"}
          icon={<ListAltOutlinedIcon />}
          to={routeUrls.SUMMARYREPORTS}
        />
        <MenuListItem
          open={open}
          title={"User Statistics"}
          icon={<SupervisedUserCircleIcon />}
          to={routeUrls.USERSTATISTICS}
        />
        <MenuListItem
          open={open}
          title={"Map Reports"}
          icon={<MapIcon />}
          to={routeUrls.MAPREPORTS}
        />
      </List>
    </>
  );
}
