import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";


export const constituencyApi = createApi({

    reducerPath: 'constituencyApi',

    baseQuery: baseQuery,

    endpoints: builder => ({
        getConstituency: builder.query({
            query: ({ page = 1, pageSize = 10, search = '' }) => ({
                url: `/consti.php?q=${search}&pageSize=${pageSize}&page=${page}`,
                method: 'GET'
            })
        }),
        addConstituency: builder.mutation({
            query: (data) => ({
                url: '/consti.php',
                method: 'POST',
                body: data
            })
        }),
        getConstituencyById: builder.query({
            query: ({ id }) => ({
                url: `/consti.php?id=${id}`,
                method: 'GET'
            })
        }),

        editConstituencyById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/consti.php?id=${id}`,
                method: 'PUT',
                body: data
            })
        }),

        deleteConstituencyById: builder.mutation({
            query: ({ id }) => ({
                url: `/consti.php?id=${id}`,
                method: 'DELETE'
            })
        }),
    })
})

export const { useGetConstituencyQuery, useGetConstituencyByIdQuery, useAddConstituencyMutation, useDeleteConstituencyByIdMutation, useEditConstituencyByIdMutation } = constituencyApi;