import { useFormik } from 'formik';
import * as yup from 'yup';
import { errorToast, successToast } from '../../../utils/toastService';
import { useEditStateByIdMutation, useGetStateByIdQuery } from '../../../api/stateApi';


export default function useEditModal({ id, handleCloseModal }) {

    const [editState, { isLoading }] = useEditStateByIdMutation();
    const { data, isSuccess, isFetching, refetch } = useGetStateByIdQuery({ id: id });

    const validationSchema = yup.object({
        statename: yup
            .string()
            .required('State name is required'),
    });

    const formik = useFormik({
        initialValues: {
            statename: data?.name || ''
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {


            const formData = {
                'state': values.statename
            }

            editState({id: id, data:JSON.stringify(formData)})
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('State Updated Successfully')
                        formik.resetForm();
                    } else if (response.error) {
                        errorToast(response?.error?.data?.message)
                    }
                    handleCloseModal();
                })
                .catch((error) => {
                    errorToast('Unable To Updated State')
                    handleCloseModal();
                });

        },
    });

    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik,
        isSuccess : (isSuccess && !isFetching),
        refetch
    }
}