import { useFormik } from 'formik';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { errorToast, successToast } from '../../../utils/toastService';
import { useEditSurveyByIdMutation, useGetSurveyByIdQuery } from '../../../api/surveyApi';


export default function useEditModal({ id, handleCloseModal }) {

    const [editSurvey, { isLoading }] = useEditSurveyByIdMutation();
    const { data, isSuccess, refetch, isFetching } = useGetSurveyByIdQuery({ id: id });

    const validationSchema = yup.object({
        surveyname: yup.string().required('Survey Name is required'),
        stateID: yup.number().required('State is required'),
        districtID: yup.number().required('District is required'),
        constituencyID: yup.number().required('Vidhan Sabha Constituency is required'),
    });


    const formik = useFormik({
        initialValues: {
            districtName: '',
            stateID: 0,
            surveyname: '',
            stateID: 0,
            districtID: 0,
            constituencyID: 0,
            questions: []
        },

        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const formData = {
                survey_name: values.surveyname,
                state_id: values.stateID,
                district_id: values.districtID,
                constituency_id: values.constituencyID,
                questions: values.questions.map(question => ({
                    question_text: question.label,
                    question_type: question.type,
                    options: question.options.map(option => ({ option_text: option }))
                }))
            };
            editSurvey({ id: id, data: JSON.stringify(formData) })
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('Survey Updated Successfully');
                        formik.resetForm();
                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Updated Survey');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Updated Survey');
                })
                .finally(() => {
                    handleCloseModal();
                });

        },
    });

    useEffect(() => {
        // if (data?.questions) {
        //     formik.setValues(prevValues => ({
        //         ...prevValues,
        //         questions: data?.questions?.map(question => ({
        //             ...question,
        //             type: question.question_type.toLowerCase(), // assuming question types from backend are consistent
        //             label: question.question_text,
        //             options: (question.options || []).map(option => option?.option_text), // assuming options may not always be present
        //         }))
        //     }));
        // }
        if (data) {
            formik.setValues(prevValues => ({
                ...prevValues,
                districtName: data.district_name || '',
                stateID: data.state_id || 0,
                surveyname: data.survey_name || '',
                districtID: data.district_id || 0,
                constituencyID: data.constituency_id || 0,
                questions: (data.questions || []).map(question => ({
                    ...question,
                    type: question.question_type ? question.question_type.toLowerCase() : '',
                    label: question.question_text || '',
                    options: (question.options || []).map(option => option?.option_text || ''),
                }))
            }));
        }
    }, [data?.questions, formik.setValues])



    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik,
        isSuccess: (isSuccess && !isFetching),
        refetch
    }
}