import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";


export const districtApi = createApi({

    reducerPath: 'districtApi',

    baseQuery: baseQuery,

    endpoints: builder => ({
        getDistrict: builder.query({
            query: ({ page = 1, pageSize = 10, search = '' }) => ({
                url: `/district.php?q=${search}&pageSize=${pageSize}&page=${page}`,
                method: 'GET'
            })
        }),
        addDistrict: builder.mutation({
            query: (data) => ({
                url: '/district.php',
                method: 'POST',
                body: data
            })
        }),
        getDistrictById: builder.query({
            query: ({ id }) => ({
                url: `/district.php?id=${id}`,
                method: 'GET'
            })
        }),

        editDistrictById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/district.php?id=${id}`,
                method: 'PUT',
                body: data
            })
        }),

        deleteDistrictById: builder.mutation({
            query: ({ id }) => ({
                url: `/district.php?id=${id}`,
                method: 'DELETE'
            })
        }),
    })
})

export const { useGetDistrictQuery, useGetDistrictByIdQuery, useAddDistrictMutation, useDeleteDistrictByIdMutation, useEditDistrictByIdMutation } = districtApi;