import { ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { Link, useLocation } from "react-router-dom";

export default function MenuListItem({ title, open, icon, onItemClick, to }) {

    const location = useLocation();

    return (
        <>
            <ListItem disablePadding sx={{
                display: 'block',
                px: open ? 2 : 1,
                mt: 0.5,
                '& .MuiListItemButton-root': {
                    borderRadius: '10px',
                    backgroundColor: location?.pathname === to ? 'secondary.main' : '', // Change background color based on selection
                    color: location?.pathname === to ? 'primary.main' : '', // Change text color based on selection
                    '.MuiTypography-root': {
                        fontWeight: location?.pathname === to ? '600' : '400',
                    },
                    '& .MuiListItemIcon-root': {
                        color: location?.pathname === to ? 'primary.main' : '', // Change icon color on hover
                    },
                    ':hover': {
                        backgroundColor: 'secondary.main', // Change background color on hover
                        color: 'primary.main', // Change text color on hover
                        '& .MuiListItemIcon-root': {
                            color: 'primary.main', // Change icon color on hover
                        }
                    },
                },
            }}
            >
                <ListItemButton
                    component={Link} // Use Link component from React Router
                    to={to}
                    sx={{
                        minHeight: 48,
                        justifyContent: open ? 'initial' : 'center',
                        px: 3.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: open ? 2 : 'auto',
                            justifyContent: 'center',
                        }}
                    >
                        {icon}
                    </ListItemIcon>
                    <ListItemText primary={title} sx={{
                        opacity: open ? 1 : 0,
                        '.MuiTypography-root': {
                            fontSize: '0.875rem'
                        }
                    }} />
                </ListItemButton>
            </ListItem >
        </>
    )
}