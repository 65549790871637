import { createApi } from "@reduxjs/toolkit/query/react";
import baseQuery from "../baseQuery";


export const villageApi = createApi({

    reducerPath: 'villageApi',

    baseQuery: baseQuery,

    endpoints: builder => ({
        getVillage: builder.query({
            query: ({ page = 1, pageSize = 10, search = '' }) => ({
                url: `/village.php?q=${search}&pageSize=${pageSize}&page=${page}`,
                method: 'GET'
            })
        }),
        addVillage: builder.mutation({
            query: (data) => ({
                url: '/village.php',
                method: 'POST',
                body: data
            })
        }),
        getVillageById: builder.query({
            query: ({ id }) => ({
                url: `/village.php?id=${id}`,
                method: 'GET'
            })
        }),

        editVillageById: builder.mutation({
            query: ({ id, data }) => ({
                url: `/village.php?id=${id}`,
                method: 'PUT',
                body: data
            })
        }),

        deleteVillageById: builder.mutation({
            query: ({ id }) => ({
                url: `/village.php?id=${id}`,
                method: 'DELETE'
            })
        }),
    })
})

export const { useGetVillageQuery, useGetVillageByIdQuery, useAddVillageMutation, useDeleteVillageByIdMutation, useEditVillageByIdMutation } = villageApi;