import React, { useEffect } from 'react';
import { Autocomplete, Box, CircularProgress, FormHelperText, Grid, Popper, TextField } from '@mui/material';

export default function SurveyBasicDetailsForm({ data, isSuccess }) {

    return (

        isSuccess ?
            (
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="surveyname"
                            name="surveyname"
                            label="Survey Name"
                            value={data?.response_data?.surveyname}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="statename"
                            name="statename"
                            label="State Name"
                            value={data?.response_data?.statename}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                    </Grid>

                    <Grid item xs={12}>
                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="districtname"
                            name="districtname"
                            label="District Name"
                            value={data?.response_data?.districtname}
                            InputProps={{
                                readOnly: true,
                            }}
                        />

                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="constituencyname"
                            name="constituencyname"
                            label="Constituency Name"
                            value={data?.response_data?.constituencyname}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12}>

                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="villagename"
                            name="villagename"
                            label="Village Name"
                            value={data?.response_data?.village}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>

                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="age"
                            name="age"
                            label="Age"
                            value={data?.response_data?.age}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>

                        <TextField
                            sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                            fullWidth
                            id="caste"
                            name="caste"
                            label="Caste"
                            value={data?.response_data?.caste}
                            InputProps={{
                                readOnly: true,
                            }}
                        />
                    </Grid>
                </Grid >
            ) :
            (
                <Box textAlign={"center"}>
                    <CircularProgress />
                </Box>
            )


    );
}
