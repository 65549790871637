import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./feature/auth/authSlice";
import { userApi } from "../api/userApi";
import { stateApi } from "../api/stateApi";
import { districtApi } from "../api/districtApi";
import { constituencyApi } from "../api/constituencyApi";
import { villageApi } from "../api/villageApi";
import { assignSurveyApi } from "../api/assignSurveyApi";
import { surveyApi } from "../api/surveyApi";
import { responseSurveyApi } from "../api/responseSurveyApi";
import { countApi } from "../api/countApi";
import { reportsApi } from "../api/reportsApi";
import { casteApi } from "../api/casteApi";

export default configureStore({
  reducer: {
    auth: authSlice,
    [userApi.reducerPath]: userApi.reducer,
    [stateApi.reducerPath]: stateApi.reducer,
    [districtApi.reducerPath]: districtApi.reducer,
    [constituencyApi.reducerPath]: constituencyApi.reducer,
    [villageApi.reducerPath]: villageApi.reducer,
    [surveyApi.reducerPath]: surveyApi.reducer,
    [assignSurveyApi.reducerPath]: assignSurveyApi.reducer,
    [responseSurveyApi.reducerPath]: responseSurveyApi.reducer,
    [countApi.reducerPath]: countApi.reducer,
    [reportsApi.reducerPath]: reportsApi.reducer,
    [casteApi.reducerPath]: casteApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(userApi.middleware)
      .concat(stateApi.middleware)
      .concat(districtApi.middleware)
      .concat(constituencyApi.middleware)
      .concat(villageApi.middleware)
      .concat(surveyApi.middleware)
      .concat(assignSurveyApi.middleware)
      .concat(responseSurveyApi.middleware)
      .concat(countApi.middleware)
      .concat(reportsApi.middleware)
      .concat(casteApi.middleware),
});
