import { AppBar, Box, Chip, CssBaseline, Divider, IconButton, List, ListItem, ListItemButton, ListItemText, Stack, Toolbar, Typography, styled, useMediaQuery, useTheme } from "@mui/material";
import MuiDrawer from '@mui/material/Drawer';
import MenuList from "./MenuList";

const drawerWidth = 260;

const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '83vh'
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    overflowY: 'auto',
    maxHeight: '83vh',
    width: `calc(${theme.spacing(8)} + 10px)`,
    [theme.breakpoints.up('sm')]: {
        width: `calc(${theme.spacing(8)} + 10px)`,
    },
});

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));


const Drawer = styled(MuiDrawer, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',

    ...(open && {
        ...openedMixin(theme),
        '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        '& .MuiDrawer-paper': closedMixin(theme),
    }),
}));


export default function Sidebar({ drawerOpen, drawerToggle }) {

    const theme = useTheme();
    const matchUpMd = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <>
            <Box component="nav" sx={{ flexShrink: { md: 0 }, width: 'auto' }} aria-label="mailbox folders">
                <Drawer
                    // variant={matchUpMd ? 'permanent' : 'temporary'}
                    variant="permanent"
                    open={drawerOpen}
                    anchor="left"
                    sx={{
                        '& .MuiDrawer-paper': {
                            borderRight: 'none',
                            '&::-webkit-scrollbar': {
                                width: '0.3em',
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#999',
                                borderRadius: '3px',
                            },
                            [theme.breakpoints.up('md')]: {
                                top: '80px'
                            }
                        }
                    }}
                >
                    <MenuList open={drawerOpen} />
                </Drawer>
            </Box>
        </>
    )
}