import { Box, Card, CardActions, CardContent, CardHeader, Chip, CircularProgress, Divider, IconButton, Pagination, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";
import SearchBar from "../../../components/SearchBar";
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';

export default function DataTable({ headers, data, page, pageSize, handleSearchValue, handlePageChange, totalPages, isSuccess, action, handleOpenViewModal, handleOpenEditModal, handleOpenDeleteModal }) {
    return (
        <>
            <Card elevation={0} sx={{ borderRadius: 3 }} >

                <CardHeader
                    sx={{
                        '.MuiCardHeader-action': {
                            alignSelf: "center",
                            mr: 1
                        }
                    }}
                    title={
                        <SearchBar handleSearchValue={handleSearchValue} />
                    }
                    action={action}
                />

                <Divider />

                <CardContent sx={{ px: 0 }}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    headers.map((data) => (
                                        <TableCell sx={{ fontWeight: 600 }}>{data}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                isSuccess ?
                                    (
                                        data && data.length > 0 ? (
                                            data.map((row, index) => (
                                                <TableRow
                                                    key={index}
                                                >
                                                    <TableCell component="th" scope="row">
                                                        {(page - 1) * pageSize + index + 1}
                                                    </TableCell>
                                                    <TableCell>{row?.name}</TableCell>
                                                    <TableCell>
                                                        <Tooltip title="View State">
                                                            <IconButton aria-label="view" size="small" onClick={() => handleOpenViewModal({ id: row?.id })} >
                                                                <VisibilityIcon color="action" fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Edit State">
                                                            <IconButton aria-label="edit" size="small" onClick={() => handleOpenEditModal({ id: row?.id })}>
                                                                <EditIcon color="primary" fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                        <Tooltip title="Delete State">
                                                            <IconButton aria-label="delete" size="small" onClick={() => handleOpenDeleteModal({ id: row?.id })}>
                                                                <DeleteIcon color="error" fontSize="inherit" />
                                                            </IconButton>
                                                        </Tooltip>
                                                    </TableCell>
                                                </TableRow>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4} align="center">No records found</TableCell>
                                            </TableRow>
                                        )
                                    ) :
                                    (
                                        <TableRow>
                                            <TableCell sx={{ textAlign: "center", py: 5 }} colSpan={4}>
                                                <CircularProgress />
                                            </TableCell>
                                        </TableRow>
                                    )
                            }
                        </TableBody>
                    </Table>
                </CardContent>

                <CardActions sx={{ pb: 3 }}>
                    <Pagination count={totalPages} page={page} onChange={handlePageChange} color="primary" />
                </CardActions>
            </Card>
        </>
    )
}