import { Autocomplete, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Popper, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { LoadingButton } from "@mui/lab";
import useEditModal from "./useEditModal";
import { useGetStateQuery } from "../../../api/stateApi";
import { useEffect } from "react";

export default function EditDistrictModal({ id, openModal, handleCloseModal }) {
    const { formik, refetch, isLoading, isSuccess, handleSubmit } = useEditModal({ id, handleCloseModal });

    const { data: stateData, isSuccess: isStateSuccess, refetch: stateRefetch } = useGetStateQuery({ page: 1, pageSize: 10000 });

    useEffect(() => {
        refetch();
        stateRefetch();
    }, [])

    return (
        <>
            <Dialog fullWidth sx={{
                '.MuiDialog-paper': {
                    borderRadius: 4
                }
            }} open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id="customized-dialog-title">
                    Edit District
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {
                        isSuccess ?
                            (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                                fullWidth
                                                id="districtName"
                                                name="districtName"
                                                label="District"
                                                required
                                                value={formik.values.districtName}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.districtName && Boolean(formik.errors.districtName)}
                                                helperText={formik.touched.districtName && formik.errors.districtName}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Autocomplete
                                                sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                                disablePortal={false}
                                                fullWidth
                                                id="stateID"
                                                name="stateID"
                                                loading={!isStateSuccess}
                                                onBlur={formik.handleBlur}
                                                options={stateData?.data || []}
                                                getOptionLabel={(state) => state?.name || ''} // Display state name
                                                value={stateData?.data?.find(state => state.id == formik.values.stateID) || null} // Find state object based on stateID
                                                onChange={(event, newValue) => formik.setFieldValue('stateID', newValue ? newValue.id : '')} // Pass state ID
                                                renderInput={(params) => <TextField required error={formik.touched.stateID && formik.errors.stateID} {...params} label="State" />}
                                                PopperComponent={({ children, ...rest }) => (
                                                    <Popper {...rest} placement="top-start">
                                                        {children}
                                                    </Popper>
                                                )}
                                            />
                                            {formik.touched.stateID && formik.errors.stateID && (
                                                <FormHelperText sx={{ ml: 2 }} error>{formik.errors.stateID}</FormHelperText>
                                            )}
                                        </Grid>
                                    </Grid>
                                </form>
                            ) :
                            (
                                <Box textAlign={"center"}>
                                    <CircularProgress />
                                </Box>
                            )
                    }

                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" sx={{
                        backgroundColor: (theme) => theme.palette.cancel.main,
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.cancel.main, // Adjust hover color if needed
                        }, textTransform: 'none'
                    }} onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading || !isSuccess} disabled={!formik.isValid} variant="contained" sx={{ textTransform: 'none' }} autoFocus onClick={handleSubmit}>
                        Update
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}