import { useFormik } from 'formik';
import * as yup from 'yup';
import { errorToast, successToast } from '../../../utils/toastService';
import { useEditAssignSurveyByIdMutation, useGetAssignSurveyByIdQuery } from '../../../api/assignSurveyApi';


export default function useEditModal({ id, handleCloseModal, handleUpdateCallback }) {

    const [editAssignSurvey, { isLoading }] = useEditAssignSurveyByIdMutation();
    const { data, isSuccess, refetch, isFetching } = useGetAssignSurveyByIdQuery({ id: id });

    const validationSchema = yup.object({
        userID: yup
            .number()
            .required('User is required'),
        surveyID: yup
            .number()
            .required('Survey is required'),
    });

    const formik = useFormik({
        initialValues: {
            userID : data?.user_id || 0,
            surveyID: data?.survey_id || 0
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {

            const formData = {
                "survey_id": values.surveyID,
                "user_id": values.userID
            }

            editAssignSurvey({ id: id, data: JSON.stringify(formData) })
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('Assign Survey Updated Successfully');
                        formik.resetForm();
                        handleUpdateCallback();
                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Updated Assign Survey');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Updated Assign Survey');
                })
                .finally(() => {
                    handleCloseModal();
                });

        },
    });

    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik,
        isSuccess : (isSuccess && !isFetching),
        refetch
    }
}