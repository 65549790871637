import { useFormik } from 'formik';
import * as yup from 'yup';
import { errorToast, successToast } from '../../../utils/toastService';
import { useEditConstituencyByIdMutation, useGetConstituencyByIdQuery } from '../../../api/constituencyApi';


export default function useEditModal({ id, handleCloseModal }) {

    const [editConstituency, { isLoading }] = useEditConstituencyByIdMutation();
    const { data, isSuccess, refetch, isFetching } = useGetConstituencyByIdQuery({ id: id });

    const validationSchema = yup.object({
        constituencyName: yup
            .string()
            .required('Constituency Name is required'),
        districtID: yup
            .number()
            .required('District ID is required'),
    });

    const formik = useFormik({
        initialValues: {
            constituencyName: data?.name || '',
            districtID: data?.district_id || 0,
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: (values) => {

            const formData = {
                "district_id": values.districtID,
                "constituency": values.constituencyName
            }

            editConstituency({ id: id, data: JSON.stringify(formData) })
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('Constituency Updated Successfully');
                        formik.resetForm();
                        
                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Updated Constituency');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Updated Constituency');
                })
                .finally(() => {
                    handleCloseModal();
                });
        },
    });


    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik,
        isSuccess : (isSuccess && !isFetching),
        refetch
    }
}