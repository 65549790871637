import { Box, Button, Link, Typography } from "@mui/material";
import { Link as LinkRouterDom } from "react-router-dom";
import TopBar from "../../components/TopBar";
import routeUrls from "../../constants/routeUrls";

import HomeIcon from "@mui/icons-material/Home";
import DataTable from "./DataTable";
import AddCasteModal from "./AddCasteModal";
import { useEffect, useState } from "react";
import { errorToast, successToast } from "../../utils/toastService";
import ConfirmationDialogBox from "../../components/ConfirmationDialogBox";

import {
  useDeleteCasteByIdMutation,
  useGetCasteQuery,
} from "../../api/casteApi";
import ViewCasteModal from "./ViewCasteModal";
import EditCasteModal from "./EditCasteModal";

export default function CasteList() {
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(5);
  const [search, setSearch] = useState("");

  const { data, isSuccess, isFetching, refetch } = useGetCasteQuery(
    { page, pageSize, search },
    { refetchOnMountOrArgChange: true }
  );

  const [deleteCaste] = useDeleteCasteByIdMutation();

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleSearchValue = (value) => {
    setSearch(value);
  };

  const breadcrumbs = [
    <Link
      component={LinkRouterDom}
      underline="none"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(103, 58, 183)"
      to={routeUrls.DASHBOARD}
    >
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    </Link>,
    <Typography
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      Survey Location
    </Typography>,
    <Typography
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      Caste List
    </Typography>,
  ];

  const [openAddModal, setOpenAddModal] = useState(false);
  const [openViewModal, setOpenViewModal] = useState({
    id: 0,
    open: false,
  });
  const [openDeleteModal, setOpenDeleteModal] = useState({
    id: 0,
    open: false,
  });
  const [openEditModal, setOpenEditModal] = useState({
    id: 0,
    open: false,
  });

  const handleOpenAddModal = () => {
    setOpenAddModal(true);
  };

  const handleCloseAddModal = () => {
    setOpenAddModal(false);
    refetch();
  };

  const handleOpenViewModal = ({ id }) => {
    setOpenViewModal({
      id: id,
      open: true,
    });
  };

  const handleCloseViewModal = () => {
    setOpenViewModal({
      id: 0,
      open: false,
    });
  };

  const handleOpenDeleteModal = ({ id }) => {
    console.log(id);
    setOpenDeleteModal({
      id: id,
      open: true,
    });
  };

  const handleCloseDeleteModal = () => {
    setOpenDeleteModal({
      id: 0,
      open: false,
    });
  };

  const handleDeleteCaste = () => {
    deleteCaste({ id: openDeleteModal?.id })
      .then((response) => {
        if (response?.data?.success) {
          successToast("Deleted Caste Successfully");
          refetch();
        } else {
          errorToast("Unable To Caste Village");
        }
      })
      .catch((error) => {
        errorToast("Unable To Caste Village");
      });
    handleCloseDeleteModal();
  };

  const handleOpenEditModal = ({ id }) => {
    setOpenEditModal({
      id: id,
      open: true,
    });
  };

  const handleCloseEditModal = () => {
    setOpenEditModal({
      id: 0,
      open: false,
    });
    refetch();
  };

  useEffect(() => {
    refetch();
  }, []);

  return (
    <>
      <TopBar title={"Caste"} breadcrumbs={breadcrumbs} />

      {/* Table */}
      <Box mt={2}>
        <DataTable
          headers={["#", "Caste Name", "Actions"]}
          action={
            <Button
              variant="contained"
              onClick={handleOpenAddModal}
              sx={{ textTransform: "none", fontWeight: 600 }}
            >
              Add Caste
            </Button>
          }
          data={data?.data}
          totalPages={data?.pagination?.totalPages}
          page={page}
          pageSize={pageSize}
          handlePageChange={handlePageChange}
          handleSearchValue={handleSearchValue}
          handleOpenEditModal={handleOpenEditModal}
          handleOpenDeleteModal={handleOpenDeleteModal}
          handleOpenViewModal={handleOpenViewModal}
          isSuccess={isSuccess && !isFetching}
        />
      </Box>

      <AddCasteModal
        openModal={openAddModal}
        handleCloseModal={handleCloseAddModal}
      />

      {!!openViewModal?.id && (
        <ViewCasteModal
          id={openViewModal?.id}
          openModal={openViewModal?.open}
          handleCloseModal={handleCloseViewModal}
        />
      )}

      <ConfirmationDialogBox
        open={openDeleteModal.open}
        title={"Are you want to delete the caste?"}
        onAccept={handleDeleteCaste}
        onReject={handleCloseDeleteModal}
      />

      {!!openEditModal?.id && (
        <EditCasteModal
          id={openEditModal?.id}
          openModal={openEditModal?.open}
          handleCloseModal={handleCloseEditModal}
        />
      )}
    </>
  );
}
