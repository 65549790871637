import { useFormik } from 'formik';
import * as yup from 'yup';
import { useAddUserMutation } from '../../../api/userApi';
import { useState } from 'react';
import { errorToast, successToast } from '../../../utils/toastService';


export default function useAddModal({handleCloseModal}) {

    const [addUser, { isLoading }] = useAddUserMutation();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const handleClickShowConfirmPassword = () => setShowConfirmPassword((show) => !show);

    const validationSchema = yup.object({
        email: yup
            .string()
            .email('Enter a valid email')
            .required('Email is required'),
        password: yup
            .string()
            .required('Password is required'),
        confirmpassword: yup.string()
            .oneOf([yup.ref('password'), null], 'Passwords must match') // Ensure confirm password matches password
            .required('Confirm password is required'),
    });


    const formik = useFormik({
        initialValues: {
            email: '',
            password: '',
            confirmpassword: ''
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const formData = new FormData();
            formData.append("username", values?.email);
            formData.append("password", values?.password);

            addUser(formData)
            .unwrap()
            .then((response) => {
                if (response?.success) {
                    successToast('User Added Successfully')
                    formik.resetForm();
                } else if (response.error) {
                    errorToast(response?.error?.data?.message)
                }
                handleCloseModal();
            })
            .catch((error) => {
                errorToast('Unable To Add User')
                handleCloseModal();
            });

        },
    });

    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        showPassword, handleClickShowPassword,
        showConfirmPassword, handleClickShowConfirmPassword,
        formik
    }
}