import { useGetCasteByIdQuery } from "../../../api/casteApi";

export default function useViewModal({ id }) {
  const { data, isSuccess, isFetching, refetch } = useGetCasteByIdQuery({
    id: id,
  });

  return {
    data: data?.data,
    isSuccess: isSuccess && !isFetching,
    refetch,
  };
}
