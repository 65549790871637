import { Box, Typography } from "@mui/material";
import DataTable from "./DataTable";

export default function UserCountByVillage({ formData, getTotalCount }) {
  return (
    <>
      {formData && Object.keys(formData).length ? (
        <Box px={3}>
          <DataTable
            headers={["#", "Village Name", "Total Survey Done"]}
            data={formData?.data}
          />
        </Box>
      ) : (
        <Box textAlign="center" p={2}>
          No Records Found
        </Box>
      )}
    </>
  );
}
