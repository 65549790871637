import { useFormik } from 'formik';
import * as yup from 'yup';
import { useState } from 'react';
import { errorToast, successToast } from '../../../utils/toastService';
import { useAddConstituencyMutation } from '../../../api/constituencyApi';


export default function useAddModal({ handleCloseModal }) {

    const [addConstituency, { isLoading }] = useAddConstituencyMutation();

    const validationSchema = yup.object({
        constituencyName: yup
            .string()
            .required('Constituency Name is required'),
        districtID: yup
            .number()
            .required('District is required'),
    });

    const formik = useFormik({
        initialValues: {
            constituencyName: '',
            districtID: 0,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const formData = new FormData();
            formData.append("district_id",values.districtID);
            formData.append("constituency",values.constituencyName);

            addConstituency(formData)
                .unwrap()
                .then((response) => {
                    if (response?.success) {
                        successToast('Constituency Added Successfully');
                        formik.resetForm();
                        
                    } else if (response?.error) {
                        errorToast(response.error.data.message);
                    } else {
                        errorToast('Unable To Add Constituency');
                    }
                })
                .catch((error) => {
                    errorToast('Unable To Add Constituency');
                })
                .finally(() => {
                    handleCloseModal();
                });
        },
    });


    return {
        handleSubmit: formik.handleSubmit,
        isLoading: isLoading,
        formik
    }
}