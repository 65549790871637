import {
  Autocomplete,
  Box,
  Grid,
  Link,
  Popper,
  TextField,
  Typography,
} from "@mui/material";
import { Link as LinkRouterDom } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import routeUrls from "../../constants/routeUrls";
import TopBar from "../../components/TopBar";
import useStatistics from "./useStatistics";
import { useGetAllSurveyListQuery } from "../../api/surveyApi";
import Chart from "./Chart";
import { LoadingButton } from "@mui/lab";
import { useRef, useState } from "react";

export default function UserStatistics() {
  const breadcrumbs = [
    <Link
      component={LinkRouterDom}
      underline="none"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(103, 58, 183)"
      to={routeUrls.DASHBOARD}
    >
      <HomeIcon sx={{ mr: 0.5 }} fontSize="inherit" />
    </Link>,
    <Typography
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      Reports
    </Typography>,
    <Typography
      variant="body2"
      sx={{ display: "flex", alignItems: "center" }}
      color="rgb(105, 117, 134)"
    >
      User Statistics
    </Typography>,
  ];

  const {
    data: surveyData,
    isSuccess: isSurveySuccess,
    isFetching: isSurveyFetching,
    refetch: surveyRefetch,
  } = useGetAllSurveyListQuery();

  const [isSurveyReportsSuccess, setSurveyReportsSuccess] = useState(true);
  const [surveyId, setSurveyId] = useState("");
  const [surveyDetail, setSurveyDetail] = useState({});

  const {
    generatePDF,
    pdfDownloading,
  } = useStatistics({ surveyId });

  const contentToPrint = useRef(null);

  return (
    <>
      <TopBar title={"User Statistics"} breadcrumbs={breadcrumbs} />

      <Box mt={2}>
        <Chart
          contentToPrint={contentToPrint}
          surveyId={surveyId}
          surveyDetail={surveyDetail}
          setSurveyReportsSuccess={setSurveyReportsSuccess}
          title={
            <Grid container>
              <Grid item xs={12}>
                {surveyId && (
                  <LoadingButton
                    loading={pdfDownloading && isSurveyReportsSuccess}
                    onClick={() => {
                      generatePDF(null, () => contentToPrint.current);
                    }}
                    variant="contained"
                    color="info"
                    sx={{ textTransform: "none", fontWeight: 600, mb: 1 }}
                  >
                    Download Survey Reports
                  </LoadingButton>
                )}
              </Grid>
            </Grid>
          }
          action={
            <Grid>
              <Grid item xs={12}>
                <Autocomplete
                  sx={{
                    width: 250,
                    ".MuiInputBase-root": {
                      backgroundColor: "rgb(248, 250, 252)",
                    },
                  }}
                  disablePortal={false}
                  fullWidth
                  id="surveyID"
                  name="surveyID"
                  loading={!isSurveySuccess && isSurveyFetching}
                  options={surveyData?.data || []}
                  getOptionLabel={(survey) => survey?.survey_name || ""} // Display state name
                  value={
                    surveyData?.data?.find(
                      (survey) => survey.id === surveyId
                    ) || null
                  } // Find state object based on stateID
                  onChange={(event, newValue) => {
                    setSurveyId(newValue ? newValue.id : "");
                    setSurveyDetail(newValue || {});
                  }} // Pass state ID
                  renderInput={(params) => (
                    <TextField {...params} label="Survey" />
                  )}
                  PopperComponent={({ children, ...rest }) => (
                    <Popper {...rest} placement="top-start">
                      {children}
                    </Popper>
                  )}
                />
              </Grid>
            </Grid>
          }
        />
      </Box>
    </>
  );
}
