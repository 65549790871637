import { Box, Card, CardActions, CardContent, CardHeader, Chip, CircularProgress, Divider, IconButton, Pagination, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from "@mui/material";

export default function DataTable({ headers, data, totalCount }) {
    return (
        <>
            <Card elevation={0} >

                <CardContent sx={{ px: 0}}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                {
                                    headers.map((data, index) => (
                                        <TableCell key={index} sx={{ fontWeight: 600 }}>{data}</TableCell>
                                    ))
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {
                                data && Object.entries(data).length > 0 ? (
                                    Object.entries(data).map(([key, value], index) => (
                                        <TableRow key={index}>
                                            <TableCell component="th" scope="row">
                                                {index + 1}
                                            </TableCell>
                                            <TableCell>{key}</TableCell>
                                            <TableCell>{value}</TableCell>
                                            <TableCell>{((value / totalCount) * 100).toFixed(2)}%</TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow>
                                        <TableCell colSpan={3} align="center">No records found</TableCell>
                                    </TableRow>
                                )
                            }
                        </TableBody>
                    </Table>
                </CardContent>
            </Card>
        </>
    )
}