import { useEffect } from "react";
import { useGetSurveyQuery } from "../../api/surveyApi";
import { useGetVillageQuery } from "../../api/villageApi";
import { useGetConstituencyQuery } from "../../api/constituencyApi";
import { useGetDistrictQuery } from "../../api/districtApi";
import { useGetStateQuery } from "../../api/stateApi";
import { useGetUserQuery } from "../../api/userApi";
import { useGetCountQuery } from "../../api/countApi";

export default function useDashboard() {
    const { data: surveyData, isSuccess: isSurveySuccess, isFetching: isSurveyFetching, refetch: surveyRefetch } = useGetSurveyQuery({ page: 1, pageSize: 1 });
    const { data: villageData, isSuccess: isVillageSuccess, isFetching: isVillageFetching, refetch: villageRefetch } = useGetVillageQuery({ page: 1, pageSize: 1 });
    const { data: constituencyData, isSuccess: isConstituencySuccess, isFetching: isConstituencyFetching, refetch: constituencyRefetch } = useGetConstituencyQuery({ page: 1, pageSize: 1 });
    const { data: districtData, isSuccess: isDistrictSuccess, isFetching: isDistrictFetching, refetch: districtRefetch } = useGetDistrictQuery({ page: 1, pageSize: 1 });
    const { data: stateData, isSuccess: isStateSuccess, isFetching: isStateFetching, refetch: stateRefetch } = useGetStateQuery({ page: 1, pageSize: 1 });
    const { data: userData, isSuccess: isUserSuccess, isFetching: isUserFetching, refetch: userRefetch } = useGetUserQuery({ page: 1, pageSize: 1 });
    const { data: countData, isSuccess: isCountSuccess, isFetching: isCountFetching, refetch: countRefetch } = useGetCountQuery();


    useEffect(() => {
        surveyRefetch();
        villageRefetch();
        constituencyRefetch();
        districtRefetch();
        stateRefetch();
        userRefetch();
        countRefetch();
    }, [])

    return {
        totalSurvey: {
            count: surveyData?.pagination?.totalCount,
            success: (isSurveySuccess && !isSurveyFetching)
        },
        totalVillage: {
            count: villageData?.pagination?.totalCount,
            success: (isVillageSuccess && !isVillageFetching)
        },
        totalConstituency: {
            count: constituencyData?.pagination?.totalCount,
            success: (isConstituencySuccess && !isConstituencyFetching)
        },
        totalDistrict: {
            count: districtData?.pagination?.totalCount,
            success: (isDistrictSuccess && !isDistrictFetching)
        },
        totalState: {
            count: stateData?.pagination?.totalCount,
            success: (isStateSuccess && !isStateFetching)
        },
        totalUser: {
            count: userData?.pagination?.totalCount,
            success: (isUserSuccess && !isUserFetching)
        },
        totalCompleteSurvey: {
            count: countData?.completed_count,
            success: (isCountSuccess && !isCountFetching)
        },
        totalOngoingSurvey: {
            count: countData?.ongoing_count,
            success: (isCountSuccess && !isCountFetching)
        }
    }
}