import { Autocomplete, Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, Popper, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import useAddModal from "./useAddModal";
import { LoadingButton } from "@mui/lab";
import { useGetConstituencyQuery } from "../../../api/constituencyApi";
import { useEffect } from "react";

export default function AddVillageModal({ openModal, handleCloseModal }) {

    const { formik, isLoading, handleSubmit } = useAddModal({ handleCloseModal });

    const { data: constituencyData, isSuccess: isConstituencySuccess, refetch: constituencyRefetch } = useGetConstituencyQuery({ page: 1, pageSize: 10000 });

    useEffect(() => {
        constituencyRefetch();
    }, [])

    return (
        <>
            <Dialog fullWidth sx={{
                '.MuiDialog-paper': {
                    borderRadius: 4
                }
            }} open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id="customized-dialog-title">
                    Add Village
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <form onSubmit={handleSubmit}>
                        <Grid container spacing={3}>
                            <Grid item xs={12}>
                                <TextField
                                    sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                    fullWidth
                                    id="villageName"
                                    name="villageName"
                                    label="Village"
                                    required
                                    value={formik.values.villageName}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.villageName && Boolean(formik.errors.villageName)}
                                    helperText={formik.touched.villageName && formik.errors.villageName}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Autocomplete
                                    sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                    disablePortal={false}
                                    fullWidth
                                    id="constituencyID"
                                    name="constituencyID"
                                    loading={!isConstituencySuccess}
                                    onBlur={formik.handleBlur}
                                    options={constituencyData?.data || []}
                                    getOptionLabel={(constituency) => constituency?.constituency_name || ''}
                                    value={constituencyData?.data?.find(constituency => constituency.id === formik.values.constituencyID) || null}
                                    onChange={(event, newValue) => formik.setFieldValue('constituencyID', newValue ? newValue.id : '')}
                                    renderInput={(params) => <TextField required error={formik.touched.constituencyID && formik.errors.constituencyID} {...params} label="Constituency" />}
                                    PopperComponent={({ children, ...rest }) => (
                                        <Popper {...rest} placement="top-start">
                                            {children}
                                        </Popper>
                                    )}
                                />

                                {formik.touched.constituencyID && formik.errors.constituencyID && (
                                    <FormHelperText sx={{ ml: 2 }} error>{formik.errors.constituencyID}</FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" sx={{
                        backgroundColor: (theme) => theme.palette.cancel.main,
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.cancel.main, // Adjust hover color if needed
                        }, textTransform: 'none'
                    }} onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading} disabled={!formik.isValid} variant="contained" sx={{ textTransform: 'none' }} autoFocus onClick={handleSubmit}>
                        Save
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}