import { Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import useEditModal from "./useEditModal";
import { useEffect } from "react";

export default function EditUserModal({ id, openModal, handleCloseModal }) {

    const { formik, refetch, isLoading, isSuccess, showPassword, handleClickShowPassword, showConfirmPassword, handleClickShowConfirmPassword, handleSubmit } = useEditModal({ id, handleCloseModal });

    useEffect(()=>{
        refetch();
    },[])

    return (
        <>
            <Dialog fullWidth sx={{
                '.MuiDialog-paper': {
                    borderRadius: 4
                }
            }} open={openModal}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">

                <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontWeight: 600 }} id="customized-dialog-title">
                    Edit User
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleCloseModal}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    {
                        isSuccess ?
                            (
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12}>
                                            <TextField
                                                sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                                fullWidth
                                                id="email"
                                                name="email"
                                                label="Email"
                                                required
                                                value={formik.values.email}
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                error={formik.touched.email && Boolean(formik.errors.email)}
                                                helperText={formik.touched.email && formik.errors.email}
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl
                                                sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                                variant="outlined" fullWidth>
                                                <InputLabel required htmlFor="password">Password</InputLabel>
                                                <OutlinedInput
                                                    id="password"
                                                    name="password"
                                                    label="Password"
                                                    type={showPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowPassword}
                                                                edge="end"
                                                            >
                                                                {showPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    value={formik.values.password}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.password && Boolean(formik.errors.password)}
                                                />
                                                {formik.touched.password && formik.errors.password && (
                                                    <FormHelperText error>{formik.errors.password}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormControl
                                                sx={{ '.MuiInputBase-root': { backgroundColor: 'rgb(248, 250, 252)' } }}
                                                variant="outlined" fullWidth>
                                                <InputLabel required htmlFor="confirmpassword">Confirm Password</InputLabel>
                                                <OutlinedInput
                                                    id="confirmpassword"
                                                    name="confirmpassword"
                                                    label="Confirm Password"
                                                    type={showConfirmPassword ? 'text' : 'password'}
                                                    endAdornment={
                                                        <InputAdornment position="end">
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                onClick={handleClickShowConfirmPassword}
                                                                edge="end"
                                                            >
                                                                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    }
                                                    value={formik.values.confirmpassword}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.confirmpassword && Boolean(formik.errors.confirmpassword)}
                                                    helperText={formik.touched.confirmpassword && formik.errors.confirmpassword}
                                                />
                                                {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                                                    <FormHelperText error>{formik.errors.confirmpassword}</FormHelperText>
                                                )}
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                </form>
                            ) :
                            (
                                <Box textAlign={"center"}>
                                    <CircularProgress />
                                </Box>
                            )
                    }

                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <Button variant="contained" sx={{
                        backgroundColor: (theme) => theme.palette.cancel.main,
                        '&:hover': {
                            backgroundColor: (theme) => theme.palette.cancel.main, // Adjust hover color if needed
                        }, textTransform: 'none'
                    }} onClick={handleCloseModal}>
                        Cancel
                    </Button>
                    <LoadingButton loading={isLoading || !isSuccess} disabled={!formik.isValid} variant="contained" sx={{ textTransform: 'none' }} autoFocus onClick={handleSubmit}>
                        Update
                    </LoadingButton>
                </DialogActions>
            </Dialog>
        </>
    )
}