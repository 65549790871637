import { Component } from "react";
import routeUrls from "../../constants/routeUrls";
import MainLayout from "../../layouts/MainLayout";
import AssignSurvey from "../../pages/AssignSurvey";
import ConstituencyList from "../../pages/ConstituencyList";
import Dashboard from "../../pages/Dashboard";
import DistrictList from "../../pages/DistrictList";
import StateList from "../../pages/StateList";
import SummaryReports from "../../pages/SummaryReports";
import SurveysList from "../../pages/SurveysList";
import Users from "../../pages/Users";
import UserReports from "../../pages/UsersReports";
import VillageList from "../../pages/VillageList";
import CasteList from "../../pages/CasteList";
import UserStatistics from "../../pages/UserStatistics";
import MapReports from "../../pages/MapReports";

const PrivateRoutes = {
  path: "/",
  Component: MainLayout,
  children: [
    { path: routeUrls.DASHBOARD, Component: Dashboard },
    { path: routeUrls.USERS, Component: Users },

    { path: routeUrls.STATELIST, Component: StateList },
    { path: routeUrls.DISTRICTLIST, Component: DistrictList },
    { path: routeUrls.CONSTITUENCYLIST, Component: ConstituencyList },
    { path: routeUrls.VILLAGELIST, Component: VillageList },
    { path: routeUrls.CASTELIST, Component: CasteList },

    { path: routeUrls.SURVEYSLIST, Component: SurveysList },
    { path: routeUrls.ASSIGNSURVEY, Component: AssignSurvey },

    { path: routeUrls.SUMMARYREPORTS, Component: SummaryReports },
    { path: routeUrls.USERSTATISTICS, Component: UserStatistics },
    { path: routeUrls.MAPREPORTS, Component: MapReports },
  ],
};

export default PrivateRoutes;
