import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Step, StepLabel, Stepper } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { useEffect, useState } from "react";
import useViewModal from "./useViewModal";
import SurveyBasicDetailsForm from "./SurveyBasicDetailsForm";
import AddQuestionsForm from "./AddQuestionsForm";

export default function ViewResponseDetailModal({ id, openModal, handleCloseModal }) {

    const { data, refetch, isSuccess } = useViewModal({ id });

    const [activeStep, setActiveStep] = useState(0);
    const steps = ['Survey Basic Details', 'Questions Details'];

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    useEffect(() => {
        refetch();
    }, [])

    return (
        <>
            <Dialog fullWidth maxWidth={"md"} open={openModal} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle sx={{ textAlign: 'center' }}>Survey Response</DialogTitle>
                <IconButton aria-label="close" onClick={handleCloseModal} sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}>
                    <CloseIcon />
                </IconButton>

                <Stepper sx={{ mb: 2 }} activeStep={activeStep} alternativeLabel>
                    {steps.map((label) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                        </Step>
                    ))}
                </Stepper>
                <DialogContent dividers>
                    {activeStep === 0 && <SurveyBasicDetailsForm data={data} isSuccess={isSuccess} />}
                    {activeStep === 1 && <AddQuestionsForm data={data} isSuccess={isSuccess} />}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseModal}>Cancel</Button>
                    <Button onClick={handleBack} disabled={activeStep === 0}>Back</Button>
                    <Button onClick={handleNext} disabled={activeStep === 1} variant="contained" color="primary">
                        Next
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}