import { Box } from "@mui/material";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import Header from "./Header";
import Sidebar from "./Sidebar";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import routeUrls from "../../constants/routeUrls";
import { ToastContainer } from "react-toastify";

export default function MainLayout() {

    const [leftDrawerOpened, setLeftDrawerOpened] = useState(true);
    const location = useLocation();
    const { isAuthenticated } = useSelector((state) => state.auth);

    const drawerToggle = () => {
        setLeftDrawerOpened(!leftDrawerOpened);
    }

    if (!isAuthenticated) {
        return <Navigate to={routeUrls.LOGIN} state={{ from: location }} />
    }

    return (
        <>

            <ToastContainer />
            <Header drawerToggle={drawerToggle} />
            <Box display={"flex"}>
                <Sidebar drawerOpen={leftDrawerOpened} drawerToggle={drawerToggle} />
                <Box sx={{
                    flexGrow: 1,
                    marginTop: '80px', minHeight: 'calc(-80px + 100vh)', backgroundColor: 'rgb(238, 242, 246)', mr: 3, borderRadius: '8px 8px 0px 0px',
                    p: 2
                }}>
                    <Outlet />
                </Box>
            </Box>
            {/* <Footer /> */}
        </>
    )
}