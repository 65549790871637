import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import SurveyLogoImg from '../../../assets/images/survey-img.png';
import { useState } from 'react';
import { Avatar, ButtonBase, Grid, useMediaQuery } from '@mui/material';
import ProfileSection from './ProfileSection';

export default function Header({ drawerToggle }) {

    return (
        <Box sx={{ flexGrow: 1 }}>
            <AppBar elevation={0} position="fixed" color='inherit'>
                <Toolbar sx={{ py: 2 }}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                        <Box display={"flex"} alignItems={"center"}>
                            <Box sx={{
                                display: {
                                    xs: 'none',
                                    md: 'flex',
                                },
                            }} alignItems={"center"} marginRight={10}>
                                <Avatar alt="Survey App" src={SurveyLogoImg} />
                                <Typography
                                    variant="h6"
                                    noWrap
                                    component="div"
                                    fontWeight={"bold"}
                                    mx={1}
                                >
                                    Survey
                                </Typography>
                            </Box>

                            <ButtonBase sx={{ borderRadius: '9px', overflow: 'hidden' }} onClick={drawerToggle}>
                                <Avatar variant='rounded'
                                    sx={{
                                        backgroundColor: 'secondary.main',
                                        color: 'primary.main',
                                        width: 35, height: 35,
                                        ':hover': {
                                            backgroundColor: 'primary.main',
                                            color: 'white',
                                        }
                                    }}
                                >
                                    <MenuIcon fontSize='small' />
                                </Avatar>
                            </ButtonBase>
                        </Box>
                        <Box textAlign={"end"}>
                            <ProfileSection />
                        </Box>
                    </Box>
                </Toolbar>
            </AppBar>
        </Box>
    );
}