import { Avatar, Box, Card, CardContent, CardMedia, Grid, Typography } from "@mui/material";

export default function DashboardCard({ color, title, count, icon }) {
    return (
        <>
            <Card elevation={0} pt={1} sx={{ backgroundColor: color, borderRadius: 3 }}>
                <CardContent sx={{ color: 'white', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <Grid container alignItems={"center"}>
                        <Grid item xs={8}>
                            <Typography variant="h6">
                                {title}
                            </Typography>
                            <Typography variant="h5" fontWeight={"600"} mt={1}>
                                {count}
                            </Typography>
                        </Grid>
                        <Grid item xs={4} textAlign={"end"}>
                            {icon}
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>
        </>
    )
}