import { Box, Grid, Paper, Typography } from "@mui/material";

export default function SurveyInformation({ data }) {
    return (
        <Box p={3}>
            <Paper elevation={0} sx={{ p: 3 }}>
                <Typography variant="h5" fontWeight="bold" mb={3}>
                    Survey Information
                </Typography>

                 <Grid container spacing={2}>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <strong>Survey Name:</strong> {data?.survey_name || "N/A"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <strong>State Name:</strong> {data?.state_name || "N/A"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <strong>District Name:</strong> {data?.district_name || "N/A"}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant="body1">
                            <strong>Constituency Name:</strong> {data?.constituency_name || "N/A"}
                        </Typography>
                    </Grid>
                </Grid>
            </Paper>
        </Box>
    );
}


